/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import SystemMessage from 'components/SystemMessage';
import CollapseBox from 'components/CollapseBox/CollapseBox';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  resetVisibilities,
  billingTierChange,
  calculate,
  override,
  reset,
  resetInitialization,
} from 'redux/pages/case-finances/financeSlice';
import FinanceForm from './FinanceForm';
import './FinanceSection.css';
import classnames from 'classnames';

const FinanceSection = ({
  name,
  isClosedCase,
  isLocked,
  patientCase: { code },
  legacyValidator,
  dispatchValidState,
  sectionCode,
  ...rest
}) => {
  const dispatch = useDispatch();
  const {
    error,
    fieldSettings,
    initialized,
    isLoading,
    showResetButton,
  } = useSelector((state) => state.app.finances);

  const overrideCalculationWithoutDebounce = useCallback(
    (name, value) => {
      dispatchValidState();

      dispatch(
        override({
          fieldName: fieldSettings[name].name,
          fieldValue: value,
          patientCaseCode: code,
        }),
      );
    },
    [code, dispatch, dispatchValidState, fieldSettings],
  );

  const overrideCalculation = useCallback(
    debounce(overrideCalculationWithoutDebounce, 1000),
    [overrideCalculationWithoutDebounce],
  );

  const handleBillingTierChange = (name, { value }) => {
    dispatch(billingTierChange());
    overrideCalculationWithoutDebounce(name, value);
  };

  const resetClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(reset({ code }));
    },
    [dispatch, code],
  );

  useEffect(() => {
    dispatch(resetInitialization());
    dispatch(calculate({code, sectionCode}));
    return () => {
      dispatch(resetVisibilities());
    };
  }, [dispatch, code]);

  return (
    <CollapseBox
      titleIcon="card"
      title={name}
      additionalHeaderItems={
        showResetButton && !isClosedCase && !isLocked && (
          <div
            className={classnames([
              'btn btn-default pull-right',
              { disabled: isLoading },
            ])}
            id="resetCalculationButton"
            onClick={isLoading ? () => {} : resetClick}
          >
            Reset To Default
          </div>
        )
      }
    >
      <div style={{ display: 'relative' }}>
        {error && <SystemMessage message={error} type="error" shown />}

        {!initialized && (
          <div style={{ height: 50, textAlign: 'center' }}>
            <LoadingIndicator type="static" />
          </div>
        )}

        <FinanceForm
          overrideCalculation={overrideCalculation}
          onBillingTierChange={handleBillingTierChange}
          legacyValidator={legacyValidator}
          {...rest}
        />
      </div>
    </CollapseBox>
  );
};

export default FinanceSection;
