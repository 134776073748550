import React, { Fragment, useContext } from 'react';
import { FINANCE_FIELDS } from '../../../../redux/pages/case-finances/financeSlice';
import Control from 'components/Form/Control';
import ControlSelector from 'components/Controls/ControlSelector';
import { Col, ControlLabel, Row } from 'react-bootstrap';
import './FinanceSection.css';
import classnames from 'classnames';
import { LABEL_COL } from './AmountField';
import FinanceContext from './FinanceContext';
import AmountField, { formatReadonly } from './AmountField';
import { useSelector } from 'react-redux';

const StaffFields = ({ onBillingTierChange }) => {
  const { isLoading, fieldVisibilites, calculateUpdateSuccess } = useContext(FinanceContext);

  const { billingTiers } = useSelector((state) => state.app.finances);
  const isClosedCase = 'CW_CLOSED' === useSelector((state) => state.app.cases.editedCase.currentStage.code);
  const isLocked = useSelector((state) => state.app.cases.caseLockInfo.locked);

  let tickMark = calculateUpdateSuccess ? ' ✔' : ' ❌';
  let color = calculateUpdateSuccess ? 'green' : 'red';
  let tickMarkHtml = calculateUpdateSuccess != null && <span style={{ color }}>{tickMark}</span>;

  return (
    <>
      <Row id="billingTierRow"
        className={classnames({ hidden: billingTiers.length === 0 })}
      >
        <Col md={6}>
          {fieldVisibilites[FINANCE_FIELDS.billingTier] && (
            <Row>
              <Col md={LABEL_COL}>
                <ControlLabel className="financeSection__amountField-label">
                  Billing Tier
                  {tickMarkHtml}
                </ControlLabel>
              </Col>
              <Col md={8}>
                <Control
                  name={FINANCE_FIELDS.billingTier}
                  component={ControlSelector}
                  container={Fragment}
                  options={billingTiers}
                  isLoading={isLoading}
                  isDisabled={isLoading || isClosedCase || isLocked}
                  onChange={onBillingTierChange}
                  readOnly={!isLoading && billingTiers.length === 1}
                  formatReadonlyValue={({ label }) => formatReadonly()(label)}
                  required
                  hideLabel
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Col md={8}>
        <Row className="mg-top-10">
          <AmountField
            name={FINANCE_FIELDS.maxBillableAmount}
            isClosedCase={isClosedCase}
            isLocked={isLocked}
            labelVisibilityDependsOn={
              FINANCE_FIELDS.maxBillableAmountInReviewerCurrency
            }
          />
          <AmountField
            name={FINANCE_FIELDS.maxBillableAmountInReviewerCurrency}
            hideLabel
            isClosedCase={isClosedCase}
            isLocked={isLocked}
          />
          <AmountField
            name={FINANCE_FIELDS.maxBillableAmountHourlyRate}
            hideLabel
            isClosedCase={isClosedCase}
            isLocked={isLocked}
          />
        </Row>
      </Col>
      <Col md={4}>
        <Row className="mg-top-10">
            <AmountField
              name={FINANCE_FIELDS.maxBillableTime}
              single
              isClosedCase={isClosedCase}
              isLocked={isLocked}
            />
        </Row>
      </Col>
      <Col md={8}>
        <Row className="mg-top-10">
          <AmountField
            name={FINANCE_FIELDS.reviewerAmount}
            isClosedCase={isClosedCase}
            isLocked={isLocked}
            labelVisibilityDependsOn={
              FINANCE_FIELDS.reviewerAmountInReviewerCurrency
            }
          />
          <AmountField
            name={FINANCE_FIELDS.reviewerAmountInReviewerCurrency}
            isClosedCase={isClosedCase}
            isLocked={isLocked}
            hideLabel
          />
          <AmountField
            name={FINANCE_FIELDS.reviewerAmountHourlyRate}
            isClosedCase={isClosedCase}
            isLocked={isLocked}
            hideLabel
          />
        </Row>
      </Col>
      <Col md={4}>
        <Row className="mg-top-10">
          <AmountField
            name={FINANCE_FIELDS.reviewerTime}
            single
            isClosedCase={isClosedCase}
            isLocked={isLocked}
          />
        </Row>
      </Col>
      <Col md={8}>
        <Row className="mg-top-10">
          <AmountField name={FINANCE_FIELDS.clientAmount} isClosedCase={isClosedCase} isLocked={isLocked} />
          <AmountField name={FINANCE_FIELDS.billingTierHourlyRate} hideLabel isClosedCase={isClosedCase} isLocked={isLocked} />
        </Row>
        <Row className="mg-top-10">
          <AmountField name={FINANCE_FIELDS.caseMargin} isClosedCase={isClosedCase} isLocked={isLocked} />
        </Row>
      </Col>
      <Col md={4}>
        <Row className="mg-top-10">          
          <AmountField
            name={FINANCE_FIELDS.clientTime}
            single
            isClosedCase={isClosedCase}
            isLocked={isLocked}
          />
        </Row>
      </Col>
    </>
  );
};

export default StaffFields;
