import React from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {getCase, setCaseMessage, updateCaseLocking} from "../../../redux/actions/cases/caseActions";
import {caseUpdateAssignment} from "../../../redux/actions/cases/caseAssignActions";

class CaseAccept extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.acceptCase = this.acceptCase.bind(this);
    }

    acceptCase(e) {
        e.preventDefault();
        let aDetails = this.props.assignmentDetails;
        let detailCode = '';
        for (let i = 0; i < aDetails.length; i++) {
            if (aDetails[i].reviewerCode === this.props.currentUser.code) {
                detailCode = aDetails[i].code;
            }
        }
        if (detailCode !== '') {
            this.props.updateCaseLocking(false).then(() => {
                // this.props.setCaseMessage({
                //     'text': 'Case successfully accepted',
                //     'type': 'success'
                // });
                this.props.caseUpdateAssignment(detailCode, 'ACCEPTED').then(() => {
                  this.props.getCase(this.props.case.code);
                });
            });
        }
    }

    render() {
        const context = this.context;
        let disabled = this.props.disabledReviewerButtons || this.props.loading;
        return (
            <span>
                <button
                    disabled={disabled}
                    className="btn resizable-button tooltip-button btn-success margin-left-5"
                    onClick={this.acceptCase}>
                    {context.translate('common.cases.accept')}
                </button>
            </span>
        )
    }
}

CaseAccept.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    return {
        loading: state.app.cases.loading,
        currentUser: state.app.auth.user,
        case: state.app.cases.editedCase,
        assignmentDetails: state.app.case_assign.appliedReviewers,
        disabledReviewerButtons: state.app.cases.disabledReviewerButtons,
        isLocked: state.app.cases.caseLockInfo.locked,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCase: (code) => dispatch(getCase(code)),
        setCaseMessage: (data) => dispatch(setCaseMessage(data)),
        caseUpdateAssignment: (detailCode, action) => dispatch(caseUpdateAssignment(detailCode, action)),
        updateCaseLocking: (shouldRefresh) => dispatch(updateCaseLocking(shouldRefresh)),
    }
};

CaseAccept = withRouter(CaseAccept);
export default connect(mapState, mapDispatchToProps)(CaseAccept);
