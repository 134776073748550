import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColoredBox from '../../../components/ColoredBox/ColoredBox';
import ConnectedDataTable from '../../../components/Datatable/ConnectedDataTable';
import filteredReviewersSlice from '../../../redux/pages/filtered-reviewers/filteredReviewersSlice';
import { DropdownButton } from 'react-bootstrap';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { capitalizeFirstLetter, tierFormatter } from '../../../utils/helpers';
import { tiers } from '../../../utils/Constants';
import { createAssignment } from '../../../redux/actions/cases/caseAssignActions';
import { ColorCircle } from './ColorCircle';

const columns = {
  name: {
    label: 'Name',
    colStyle: { width: '15%' },
    format: (name, row) => {
      const col = row.acceptedCaseCountStatus.toLowerCase();
      return (
        <>
          <ColorCircle color={col}/> {name}
        </>
      );
    },
  },
  specialties: {
    label: 'Specialties',
    colStyle: { width: 'auto' },
  },
  mba: {
    label: 'MBA',
    colStyle: { width: '7%' },
  },
  qualityScore: {
    label: 'Quality Score',
    colStyle: { width: '5%' },
  },
  country: {
    label: 'Country',
    colStyle: { width: '10%' },
  },
  recommended: {
    label: 'Tier Recommendation',
    colStyle: { width: '8%' },
  },
  tier: {
    label: 'Tier',
    colStyle: { width: '6%' },
  },
  backup: {
    label: 'Backup',
    colStyle: { width: '6%' },
  },
  lastAssignment: {
    label: 'Last Assigned',
    colStyle: { width: '10%' },
  },
};

// eslint-disable-next-line react/prop-types
const FilteredReviewersPager = () => {
  const dispatch = useDispatch();

  const { code: patientCaseCode } = useSelector((state) => {
    return state.app.cases.editedCase;
  });

  const { settings: datatableSettings } = useSelector((state) => {
    return state.app.filteredReviewersReducer;
  });

  const { locked: lockedCase } = useSelector((state) => {
    return state.app.case_assign.currentFilters;
  });

  useEffect(() => {
    if (patientCaseCode) {
      dispatch(filteredReviewersSlice.actions.loadData({ ...datatableSettings, patientCaseCode: patientCaseCode }));
    }
  }, [dispatch, datatableSettings, patientCaseCode]);

  const handleRowClick = (rowData) => {
    window.open(`#/reviewers/profile/${rowData.code}`, '_blank');
  };

  return (
    <ColoredBox variant="primary" title="">
      <ConnectedDataTable
        defaultLoadData={false}
        columns={columns}
        onRowClick={handleRowClick}
        stateKey="filteredReviewersReducer"
        actions={filteredReviewersSlice.actions}
        variant="primary"
        keyExtractor={({ code }) => code}
        deleteDisabled={({ client }) => client && client.length > 0}
        className={'datatable__filtered-reviewers'}
        actionsTitle=""
        customActions={(reviewerRowData) => {
          let selectableTiers = tiers.filter((tier) => tierFormatter(tier) !== reviewerRowData.tier);
          return (
            <DropdownButton bsStyle='primary'
                            title='Move To'
                            disabled={lockedCase}>
              {
                selectableTiers.map(
                  (tier) =>
                    <MenuItem onSelect={() => dispatch(createAssignment(patientCaseCode, reviewerRowData.code, tier))}>
                      {capitalizeFirstLetter(tier)}
                    </MenuItem>
                )
              }
            </DropdownButton>
          );
        }}
      />
    </ColoredBox>
  );
};

FilteredReviewersPager.propTypes = {};

export default FilteredReviewersPager;
