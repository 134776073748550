import React from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { attachFiles, getCase, getCaseFiles, setCaseMessage } from "../../../redux/actions/cases/caseActions";
import { isClient, isReviewer } from "../../../utils/helpers";
import FilePasswordModal from 'pages/FilePasswordModal';
import { checkFileEncryption } from '../Attachments/attachmentUtils';

class CaseUploadFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            modalFileName: '',
            modalPassword: '',
            resolveModalPassword: null,
        };

        this.onSaveFiles = this.onSaveFiles.bind(this);
        this.openFilePasswordModal = this.openFilePasswordModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
    }

    async openFilePasswordModal(filename) {
        return new Promise((resolve, reject) => {
            this.setState({ modalOpen: true, modalFileName: filename });
            this.resolveModalPassword = resolve;
        });
    };

    handleCloseModal() {
        this.setState({ modalOpen: false, modalFileName: '', modalPassword: '' });
        this.resolveModalPassword(null);
    }

    handleSubmitPassword(filePassword) {
        this.setState({ modalOpen: false, modalFileName: '', modalPassword: '' });
        this.resolveModalPassword(filePassword);
    }

    async onSaveFiles(savedFiles) {
        let caseCode = this.props.case.code;
        let files = savedFiles.target.files;
        let type = 'STAFF_ATTACHMENT';
        if (isClient(this.props.currentUser.roleCode)) type = 'CLIENT_ATTACHMENT';
        if (isReviewer(this.props.currentUser.roleCode)) type = 'REVIEWER_ATTACHMENT';

        const passwords = [];
        for (const file of files) {
            const isEncrypted = await checkFileEncryption(file);
            if (isEncrypted) {
                const password = await this.openFilePasswordModal(file.name);
                passwords.push(password);
            } else {
                passwords.push(null);
            }
        }

        this.props.attachFiles({
            userCode: this.props.currentUser.code,
            code: this.props.case.code,
            type: type,
            files: files,
            passwords: passwords
        }).then(() => {
            this.props.getCase(caseCode)
            .then(() => {
              this.props.getCaseFiles(caseCode);
            });
        }).catch((error) => {
          this.props.dispatch(setCaseMessage({
            text:  error?.response?.data?.error,
            type: 'error',
            time: '5000',
          }));
        });
    }

    resetFileField(event) {
        event.target.value = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dropZoneFiles !== this.props.dropZoneFiles) {
            this.onSaveFiles(this.props.dropZoneFiles);
        }
    }

    render() {
        const { buttonName } = this.props;
        const { modalOpen, modalFileName } = this.state;
        let uploadIsAvailable = '';
        if (isReviewer(this.props.currentUser.roleCode)) {
            let detailAction = '';
            if (this.props.case) {
                let aDetails = this.props.assignmentDetails;
                for (let i = 0; i < aDetails.length; i++) {
                    if (aDetails[i].user === this.props.currentUser.code) {
                        detailAction = aDetails[i].action;
                    }
                }
            }
            if (detailAction !== 'ACCEPTED') {
                uploadIsAvailable = 'disabled';
            }
        }
        if (this.props.isLocked) {
          uploadIsAvailable = 'disabled';
        }
        const isClosed = this.props.currentStageCode.indexOf('CLOSED') > 0;
        const context = this.context;

        let visibility_button = this.props.modify_cases ? 'visible' : 'hidden';
        const accept = 'image/jpeg,image/png,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.doc,.docs,.ppt,.pptx,.pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation';

        return (
            <span className="upload_button"
                title="Formats, available for uploading: pdf,&nbsp;jpg,&nbsp;jpeg,&nbsp;png,&nbsp;doc,&nbsp;docx,&nbsp;ppt,&nbsp;pptx"
                style={{
                    display: 'flat',
                    visibility: visibility_button,
                    marginRight: buttonName ? '55px' : '',
                    float: buttonName ? 'right' : ''
                }}
            >
                <form className="md-form" style={{ display: isClosed ? 'none' : 'block' }}>
                    <label style={{ maxWidth: buttonName ? '' : '50px' }}>
                        {uploadIsAvailable === '' ?
                            <input type="file" onChange={this.onSaveFiles} multiple onClick={this.resetFileField}
                                accept={accept} />
                            : ''}
                        {buttonName ?
                            <span className={`btn btn-default margin-left-5 ${uploadIsAvailable}`}>
                                {context.translate('common.case.' + buttonName)}
                            </span>
                            :
                            <span className={`btn btn-default resizable-button tooltip-button margin-left-5`}
                                style={{
                                    borderColor: 'rgb(221,221,221)',
                                    cursor: uploadIsAvailable === '' ? 'pointer' : 'not-allowed'
                                }}>
                                <i className={`fa fa-paperclip`} />
                            </span>
                        }
                    </label>
                </form>
                {modalOpen && (
                    <FilePasswordModal
                        isOpen={modalOpen}
                        filename={modalFileName}
                        onCancel={this.handleCloseModal}
                        onSubmit={this.handleSubmitPassword}
                    />
                )}
            </span>
        )
    }
}

CaseUploadFile.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    let my_permissions = state.app.users.my_permissions;
    return {
        currentUser: state.app.auth.user,
        case: state.app.cases.editedCase,
        currentStageCode: state.app.cases.editedCase.currentStage.code,
        modify_cases: my_permissions.includes('MODIFY_CASES'),
        files: state.app.cases.files,
        assignmentDetails: state.app.case_assign.appliedReviewers,
        isLocked: state.app.cases.caseLockInfo.locked,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setCaseMessage: (data) => dispatch(setCaseMessage(data)),
        attachFiles: (code) => dispatch(attachFiles(code)),
        getCase: (code) => dispatch(getCase(code)),
        getCaseFiles: (code) => dispatch(getCaseFiles(code))
    }
};

export default connect(mapState, mapDispatchToProps)(withRouter(CaseUploadFile));
