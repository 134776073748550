import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import "../ModalWin.css"

import CaseSubmit from "../../pages/Case/Buttons/CaseSubmit";
import CaseSubmitFromGrid from "../../pages/Case/Buttons/CaseSubmitFromGrid";
import CaseSubmitTo from "../../pages/Case/Buttons/CaseSubmitTo";
import CaseGetReport from "../../pages/Case/Buttons/CaseGetReport";
import GetSeveralReports from "../../pages/Case/Buttons/GetSeveralReports";
import CaseReopenFromGrid from "../../pages/Case/Buttons/CaseReopenFromGrid";
import CaseReopen from "../../pages/Case/Buttons/CaseReopen";
import CaseCreate from "../../pages/Case/Buttons/CaseCreate";
import CaseUploadFile from "../../pages/Case/Buttons/CaseUploadFile";
import GetSeveralAttachments from "../../pages/Case/Buttons/GetSeveralAttachments";
import LoadingIndicator from "../LoadingIndicator";
import CaseDownloadMergeDoc from "../../pages/Case/Buttons/CaseDownloadMergeDoc";
import CaseRemove from 'pages/Case/Buttons/CaseRemove';
import CaseForceEdit from 'pages/Case/Buttons/CaseForceEdit';

class CaseManageButtons extends React.Component {
    constructor(props) {
        super(props);
        this.getLink = this.getLink.bind(this);
    }

    getLink() {
        if (this.props.currentPage.indexOf('case/') === 1) {
            if (this.props.matrixCode.indexOf('#ROLE_REVIEWER') === -1 &&
                this.props.matrixCode.indexOf('#ROLE_CLIENT') === -1) {
                return (
                    <span style={{ clear: 'both' }}>
                        <CaseSubmit/>
                        <CaseSubmitTo/>
                        {/*<button className={`btn resizable-button tooltip-button `}
                                style={{marginRight: '5px'}}>
                            <i className={`fa fa-comments`} title='Comments'/>
                        </button>*/}
                        <CaseGetReport/>
                        <CaseUploadFile/>

                        <CaseReopen/>
                        <CaseRemove/>
                        <CaseForceEdit/>

                        {/*<button className={`btn resizable-button tooltip-button `}
                                style={{marginRight: '5px'}}>
                            <i className={`fa fa-times`} title='Delete'/>
                        </button>*/}
                    </span>
                )
            } else if (this.props.matrixCode.indexOf('#ROLE_REVIEWER') === -1) {
                return (
                    <span style={{ clear: 'both' }}>
                        <CaseSubmit/>
                        {/*<button className={`btn resizable-button tooltip-button `}
                                style={{marginRight: '5px'}}>
                            <i className={`fa fa-comments`} title='Comments'/>
                        </button>*/}
                        <CaseGetReport/>
                        <CaseUploadFile/>
                        <CaseReopen/>
                        <CaseRemove/>
                        <CaseForceEdit/>
                    </span>
                )
            } else {
                // only for Reviewers
                let detailAction = '';
                if (this.props.currentCase) {
                    let aDetails = this.props.assignmentDetails;
                    for (let i = 0; i < aDetails.length; i++) {
                        if (aDetails[ i ].reviewerCode === this.props.currentUser.code) {
                            detailAction = aDetails[ i ].action;
                        }
                    }
                }
                if (detailAction === 'ACCEPTED') {
                    return (
                        <span>
                            <CaseSubmit/>
                            <CaseDownloadMergeDoc/>
                            <CaseUploadFile/>
                            <CaseForceEdit/>
                        </span>
                    )
                } else if (detailAction !== '') {
                    // the buttons have been moved to Details.js
                    return <></>;
                }
            }
        } else {
            switch (this.props.currentPage) {
                case '/':
                case '/home':
                case '/draft':
                case '/dispatch':
                case '/review':
                case '/qa':
                case '/in_progress':
                case '/complete':
                case '/closed':
                case '/flagged':
                case '/all_cases':
                    if (this.props.matrixCode.indexOf('#ROLE_REVIEWER') === -1 &&
                        this.props.matrixCode.indexOf('#ROLE_CLIENT') === -1) {
                        return (
                            <span>
                                <CaseCreate/>
                                <GetSeveralReports currentPage={this.props.currentPage}/>
                                {/*<button className={`btn resizable-button tooltip-button `}
                                        style={{marginRight: '5px', marginLeft: '10px'}} title='Comments'>
                                    <i className={`fa fa-comments`}/>
                                </button>*/}
                                {/*<GetSeveralAttachments currentPage={this.props.currentPage}/>*/}
                                {/*<button className={`btn btn-default resizable-button tooltip-button margin-left-5`}
                                        title='Copy'>
                                    <i className={`fa fa-copy`}/>
                                </button>*/}
                                {/*<CaseReopenFromGrid/>*/}
                                {/*<CaseSubmitFromGrid/>*/}
                                {/*<button className={`btn resizable-button tooltip-button `}
                                        style={{marginRight: '5px'}} title='Cancel'>
                                    <i className={`fa fa-ban`}/>
                                </button>*/}
                                {/*<button className={`btn resizable-button tooltip-button `}
                                        style={{marginRight: '5px'}} title='Delete'>
                                    <i className={`fa fa-times`}/>
                                </button>*/}
                            </span>
                        )
                    } else if (this.props.matrixCode.indexOf('#ROLE_REVIEWER') === -1) {
                        return (
                            <span>
                                <CaseCreate/>
                                <GetSeveralReports currentPage={this.props.currentPage}/>
                                {/*<button className={`btn resizable-button tooltip-button `}
                                        style={{marginRight: '5px', marginLeft: '10px'}} title='Comments'>
                                    <i className={`fa fa-comments`}/>
                                </button>*/}
                                <GetSeveralAttachments currentPage={this.props.currentPage}/>
                                <CaseReopenFromGrid/>
                                <CaseSubmitFromGrid/>
                            </span>
                        )
                    } else if (this.props.currentPage === '/' || this.props.currentPage === '/home') {
                        return (
                            <span>
                                <GetSeveralAttachments currentPage={this.props.currentPage}/>
                            </span>
                        )
                    }
                    break;
                case '/accepted':
                    return (
                        <span>
                            <GetSeveralAttachments currentPage={this.props.currentPage}/>
                        </span>
                    );
                default:
                    return ''
            }
        }
    }

    render() {
        return (
            <>
                <span>{this.getLink()}</span>
                {this.props.currentPage.indexOf('case/') === 1 && this.props.loading && <LoadingIndicator height={34} type={'caseSubmit'}/>}
            </>
        )
    }
}

CaseManageButtons.contextTypes = {
    translate: PropTypes.func
};

CaseManageButtons.propTypes = {
    matrixCode: PropTypes.string,
    currentCase: PropTypes.object,
    assignmentDetails: PropTypes.array,
    currentUser: PropTypes.object,
    currentPage: PropTypes.string,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    matrixCode: (typeof (state.app.cases.editedCase.currentStage.code) !== undefined) ?
        state.app.cases.editedCase.currentStage.code + '#' + state.app.auth.user.roleCode
        : '',
    currentCase: state.app.cases.editedCase,
    assignmentDetails: state.app.case_assign.appliedReviewers,
    currentUser: state.app.auth.user,
    currentPage: state.router.location.pathname,
    my_role: state.app.auth.user.roleCode,
    loading: state.app.cases.loading,
});

export default connect(mapStateToProps)(CaseManageButtons)
