import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { getCorrectDateFormat, isReviewer } from '../../../utils/helpers'
import { setCaseMessage } from '../../../redux/actions/cases/caseActions'
import { caseUpdateAssignment, updateAppliedReviewers } from '../../../redux/actions/cases/caseAssignActions'

const assignStatuses = [
    'ASSIGNED',
    'PREASSIGNED',
    'ACCEPTED',
    'REJECTED',
    'SKIPPED'
];

// validator function pass the user input value and should return true|false.

class TierRender extends React.Component {

    reviewerFormatter = (cell, row) => {
        return <a target='_blank' rel={'noopener noreferrer'}
                  href={`#/reviewers/profile/${row.reviewerCode}`}>{row.name}</a>
    };

    mbaFormatter = (cell, row) => {
        return <div><span>{cell}</span> <span>{row.maxBillableAmountCurrencyCode}</span></div>;
    };

    updateAssignment = (row, cellName, cellValue) => {
        const { appliedReviewers } = this.props;
        let found = false;
        appliedReviewers.forEach((item) => {
            if (item.code === row.code && item.action === cellValue) found = true;
        });

        if (!found) {
            const details = this.props.appliedReviewers.map((item) => {
                const reviewer = { ...item };
                if (item.code === row.code) {
                    return row
                } else if (cellValue === 'ACCEPTED' && reviewer.action === 'ASSIGNED') reviewer.action = 'SKIPPED';
                return reviewer
            });
            const acceptedCount = details.filter((item) => item.action === 'ACCEPTED').length;

            if ((cellValue === 'ACCEPTED' && acceptedCount > 1)
                || (cellValue === 'ASSIGNED' && acceptedCount === 1)) {
                return this.props.setCaseMessage({
                    'text': 'Case is already accepted.',
                    'type': 'error',
                    'time': '2000'
                })
            }
            this.props.caseUpdateAssignment(row.code, cellValue);
            this.props.updateAppliedReviewers(details);
        }
    };

    statusFormatter = (cell) => {
        switch (cell) {
            case 'ASSIGNED':
                return 'Assigned';
            case 'PREASSIGNED':
                return 'Pre-assigned';
            case 'ACCEPTED':
                return 'Accepted';
            case 'REJECTED':
                return 'Rejected';
            case 'SKIPPED':
                return 'Scooped';
            default:
                return 'Pre-assigned';
        }

    };

    getTierDelay = () => {
        const { tiersActivationDates, tier, defaultActivationDates } = this.props;

        let prevTier = `TIER_${tier.slice(tier.length - 1, tier.length) - 1}`;
        if (!tiersActivationDates[ prevTier ] && tier === 'TIER_3') prevTier = 'TIER_1';
        const prevDate = tiersActivationDates[ prevTier ] ? tiersActivationDates[ prevTier ] : defaultActivationDates[ 'TIER_1' ];

        const delay = Math.round(
            (tiersActivationDates[ tier ] - prevDate) / 3600000
        );

        if (delay <= 0) return 'No Delay';

        return delay + ' Hours';
    };

    getTierStatus = () => {
        const { currentStage, tiersActivationDates, tier } = this.props;
        let status;
        const stageCode = currentStage ? currentStage.code : '';

        if (stageCode === 'CW_DISPATCH') {
            status = this.getTierDelay() === 'No Delay' ? 'Not Delayed' : 'Delayed'
        } else {
            let currentTime = (new Date()).getTime();
            status = this.getTierDelay() === 'No Delay' ? 'Activated' : (
                currentTime > tiersActivationDates[ tier ] ? 'Activated' : 'Delayed')
        }

        return status;
    };

    render() {
        this.getTierDelay();

        const cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.updateAssignment
        };
        // const data = this.props.assignmentDetails.filter((item) => item.tier === this.props.tier).map((item) => ({
        //     ...item,
        //     key: item.user
        // }));
        // const style = { display: data && data.length ? 'block' : 'none' };
        const noPadding = { paddingLeft: '0', paddingRight: '0' };
        const delay = this.getTierDelay();
        const status = this.getTierStatus();

        const data = this.props.appliedReviewers.filter((item) => item.tier === this.props.tier).map((item) => {
            return ({
                ...item,
                key: item.reviewerCode
            });
        });
        const style = { display: data && data.length ? 'block' : 'none' };

        return (
            <div
                className='col-md-12 margin-top-15'
                style={style}>
                <div className='tier'>
                    <div className='row tier_header'>
                        <div className='col-md-5' style={noPadding}>
                            <span style={{
                                fontSize: '20px',
                                fontWeight: 600,
                                paddingLeft: '5px',
                                verticalAlign: 'middle',
                                width: '62px',
                                display: 'inline-block'
                            }}>{this.props.title}</span>
                            <span className='delay_badge'>
                                {status}
                            </span>
                        </div>
                        <div className='col-md-3 col-md-offset-1' style={noPadding}>
                            <span className='text-center' style={{ color: '#08437A' }}>
                                {getCorrectDateFormat(this.props.tiersActivationDates[this.props.tier], 'L LT')}
                            </span>
                        </div>
                        <div className='col-md-3' style={noPadding}>
                            <span className='text-center'>
                                {delay}
                            </span>
                        </div>
                    </div>
                    <BootstrapTable
                        data={data}
                        bodyStyle={{ overflowX: 'visible' }}
                        expandComponent={this.expandComponent}
                        condensed
                        hover
                        headerContainerClass='tier_table_header'
                        bordered={false}
                        cellEdit={cellEditProp}>
                        <TableHeaderColumn
                            dataField='key'
                            isKey={true}
                            hidden>
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='user'
                            editable={false}
                            dataFormat={this.reviewerFormatter}>Name</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='specialityNames'
                            editable={false}
                            columnTitle={true}>
                            Specialties
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='maxBillableAmount'
                            editable={false}
                            dataFormat={this.mbaFormatter}>
                            MBA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='action'
                            dataFormat={this.statusFormatter}
                            editable={this.props.isLocked ? false : { type: 'select', options: { values: assignStatuses } }}>
                            Status
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        );
    }
}

TierRender.propTypes = {
    appliedReviewers: PropTypes.array,
    setCaseMessage: PropTypes.func,
    caseUpdateAssignment: PropTypes.func,
    updateAppliedReviewers: PropTypes.func,
    tier: PropTypes.string,
    title: PropTypes.string,
    tiersActivationDates: PropTypes.object,
    defaultActivationDates: PropTypes.object,
    currentStage: PropTypes.object,
}

const mapState = (state) => {
    // console.log('mapStateToProps TIER RENDER', state.app.case_assign);
    const reviewers = state.app.users.users.filter((item) => isReviewer(item.roleCode));
    // const allSpecialties = state.app.specialties.data;
    return {
        timeZoneCode: state.app.auth.user.timeZoneCode,
        // assignmentDetails: typeof state.app.case_assign.appliedReviewers === "undefined" ? [] :
        //     state.app.case_assign.appliedReviewers.map((item) => {
        //         const reviewer = reviewers.find((reviewer) => reviewer.code === item.user);
        //        // const reviewer = reviewers.find((reviewer) => reviewer.code === item.reviewerCode);
        //
        //         return {
        //             ...reviewer,
        //             ...item,
        //             specialtyCodes: reviewer ? getNamesFromCodesSort(reviewer.specialtyCodes, allSpecialties) : []
        //         }
        //     }),
        reviewers: reviewers,
        appliedReviewers: state.app.case_assign.appliedReviewers,
        currentStage: state.app.cases.editedCase.currentStage,
        defaultActivationDates: state.app.case_assign.defaultActivationDates || {},
        tiersActivationDates: state.app.case_assign.tiersActivationDates,
        allSpecialties: state.app.specialties.data,
        isLocked: state.app.cases.caseLockInfo.locked
    }
};

const mapDispatchToProps = dispatch => ({
    updateAppliedReviewers: (reviewers) => dispatch(updateAppliedReviewers(reviewers)),
    caseUpdateAssignment: (detailCode, action) => dispatch(caseUpdateAssignment(detailCode, action)),
    setCaseMessage: (msg) => dispatch(setCaseMessage(msg))
});

export default connect(mapState, mapDispatchToProps)(TierRender);
