import React, { useContext } from 'react';
import { generateInternalCaseFile } from '../../../redux/actions/cases/caseReportsActions';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../../components/LoadingIndicator';

import SystemMessage from '../../../components/SystemMessage';
import { ClientSideDataTable } from 'components/Datatable';
import CollapseBox from 'components/CollapseBox/CollapseBox';

import { useDispatch, useSelector } from 'react-redux';
import { LegacyContext } from 'components/AppInitializer';
import { linkFormatter } from './attachmentUtils';

const InternalCaseFiles = ({
  name,
  match: {
    params: { id: caseCode },
  },
}) => {
  const dispatch = useDispatch();
  const { translate } = useContext(LegacyContext);

  const {
    loading,
    files,
    canDownloadInternalFile,
    isLocked,
    caseFileErrorMessage,
    currentStageCode,
  } = useSelector((state) => {
    const { cases } = state.app;
    return {
      caseFileErrorMessage: cases.caseFileErrorMessage,
      loading: cases.loading_internal,
      isLocked: state.app.cases.caseLockInfo.locked,
      canDownloadInternalFile: state.app.users.my_permissions.includes(
        'DOWNLOAD_INTERNAL_FILE',
      ),
      currentStageCode: cases.editedCase.currentStage.code,
      files: cases.internal_case_files,
    };
  });

  const generateFile = (event) => {
    event.stopPropagation();
    dispatch(generateInternalCaseFile(caseCode));
  };

  const columns = {
    name: {
      label: translate('common.form.internal_case_files_name'),
      colStyle: { width: '70%' },
      format: linkFormatter,
    },
    date: {
      label: translate('common.form.date_generated'),
      colStyle: { width: '30%' },
    },
  };

  const showGenerate =
    canDownloadInternalFile && !isLocked && !(currentStageCode.indexOf('CLOSED') > 0);

  const generateButton = showGenerate && (
    <span
      className="upload_button btn btn-default margin-left-5 pull-right"
      title="Generate"
      aria-disabled={true}
      onClick={generateFile}
      style={{
        marginRight: '55px',
        marginTop: -6,
      }}
    >
      {translate('common.case.generate_case_file')}
    </span>
  );

  return (
    <CollapseBox
      titleIcon="file-pdf-o"
      title={name}
      additionalHeaderItems={generateButton}
    >
      {loading && <LoadingIndicator height={50} />}
      {caseFileErrorMessage && (
        <SystemMessage
          shown={caseFileErrorMessage}
          type="error"
          message="An unrecoverable error occurred while generating the report."
          top="7em"
        />
      )}
      <ClientSideDataTable
        data={files}
        columns={columns}
        loadingPlaceholderCount={3}
      />
    </CollapseBox>
  );
};

InternalCaseFiles.contextTypes = {
  translate: PropTypes.func,
};

InternalCaseFiles.propTypes = {
  name: PropTypes.string.isRequired,
  match: PropTypes.object,
};

export default withRouter(InternalCaseFiles);
