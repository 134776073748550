import api from "../../../utils/api";
import {Statuses} from "../statuses";
import {fetchCaseCount} from '../../action-creators/init'
import {getCase, getCaseValues, setCaseMessage, getPatientCaseLock} from "./caseActions";
import history from '../../history'
import shortid from "shortid";
import { getSessionStorageKey } from "utils/case-helpers";


export const getAllCases = () => (dispatch) => {
    // role must be from 'STAFF' 'CLIENT' 'REVIEWER'
    dispatch({
        type: 'FETCH_ALL_CASES_LOADING',
    });

    api.get('case/all')
        .then(({data}) => {
            dispatch({
                type: 'FETCH_ALL_CASES_SUCCESS',
                payload: data
            });
            //buildCasesList()(dispatch, role);
            fetchCaseCount()(dispatch)

        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_ALL_CASES_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                console.log(error.request);
                dispatch({
                    type: 'FETCH_ALL_CASES_ERROR',
                    payload: Statuses[0]
                })
            } else {
                console.log('Error ', error.message);
                dispatch({
                    type: 'FETCH_ALL_CASES_ERROR',
                    payload: error.message
                });
            }
        })
};

export const getCasesActivities = () => (dispatch, getState) => {
    const {visibleCodes} = getState().app.case_list;

    if (visibleCodes) {
        if (visibleCodes.length > 3) {
            dispatch({
                type: 'FETCH_CASES_ACTIVITIES_LOADING',
            });
            api.post('case/activities', visibleCodes)
                .then(({data}) => {
                    dispatch({
                        type: 'FETCH_CASES_ACTIVITIES_SUCCESS',
                        payload: data
                    })
                })
                .catch((error) => {
                    if (error.response) {
                        dispatch({
                            type: 'FETCH_CASES_ACTIVITIES_ERROR',
                            payload: Statuses[error.response.status]
                        })
                    } else if (error.request) {
                        console.log(error.request);
                        dispatch({
                            type: 'FETCH_CASES_ACTIVITIES_ERROR',
                            payload: Statuses[0]
                        })
                    } else {
                        console.log('Error ', error.message);
                        dispatch({
                            type: 'FETCH_CASES_ACTIVITIES_ERROR',
                            payload: error.message
                        });
                    }
                })
        }
    }
};

export const createNewCase = (caseEntity) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CREATE_CASE_LOADING',
    });

    return api.post('case/create', caseEntity)
        .then(() => {
            sessionStorage.setItem(getSessionStorageKey(caseEntity.code) + '_tabCreatedAt', new Date().getTime());
            dispatch({
                type: 'FETCH_CREATE_CASE_SUCCESS',
                payload: caseEntity
            });
            dispatch({
                type: 'CLOSE_CREATE_MODAL'
            });

            fetchCaseCount()(dispatch, getState);
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: 'FETCH_CREATE_CASE_ERROR',
                    payload: Statuses[error.response.status]
                })
            } else if (error.request) {
                dispatch({
                    type: 'FETCH_CREATE_CASE_ERROR',
                    payload: Statuses[error.request.status]
                });
            }
        })
};

export const editCase = data => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_EDIT_CASE_LOADING',
    });

    // const {code} = getState().app.cases.editedCase;
    // const {needToReloadAssignments} = getState().app.cases;
    const caseCode =  getState().app.cases.editedCase.code;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(caseCode) + '_tabCreatedAt');
    data.tabTimeStamp = tabTimeStamp;
    return api.post(`case/update?tabTimeStamp=${tabTimeStamp}`, data)
        .then(({config}) => {
            dispatch({
                type: 'FETCH_EDIT_CASE_SUCCESS',
                payload: JSON.parse(config.data)
            });
            dispatch({
                type: 'CLOSE_DELETE_MODAL',
            });
            dispatch({
                type: 'CLOSE_EDIT_MODAL',
            });
            /*if (needToReloadAssignments) {
                dispatch(getFiltersByCase(code)).then(() => {
                    dispatch({
                        type: 'SET_NEED_RELOAD_ASSIGNMENTS',
                        payload: false
                    });
                    let appliedFilters = getState().app.case_assign.appliedFilters;
                    dispatch(filteredReviewersByFilter(appliedFilters, code)).then(() => {
                        let filteredReviewers = getState().app.case_assign.filteredReviewers;
                        let defaultActivationDates = getState().app.case_assign.defaultActivationDates;
                        let offset = LOCAL_TIMEZONE_OFFSET;
                        let assignments = filteredReviewers
                            .filter((item) => item.tier !== 'NO_TIER')
                            .map((item) => ({
                                acceptedCasesCount: 0,
                                action: item.status || 'PREASSIGNED',
                                activationTime: defaultActivationDates[item.tier] ? defaultActivationDates[item.tier] - offset : Date.now(),
                                active: true,
                                assignmentTime: new Date().getTime(),
                                code: shortId.generate(),
                                tier: item.tier,
                                user: item.reviewer
                            }));
                        dispatch(updateAssignmentDetails(code, assignments)).then(() => {
                            dispatch(getCaseAssignment(code));
                        });
                    });
                });
            }*/
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: 'FETCH_EDIT_CASE_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
        })
};

export const transitionCase = (data) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_EDIT_CASE_LOADING',
    });

    const {code} = getState().app.cases.editedCase;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');

    return api.post(`case/apply_transition?caseCode=${data.caseCode}&tabTimeStamp=${tabTimeStamp}&transitionCode=${data.transitionCode}`,
        data)
        .then(({config}) => {
            dispatch({
                type: 'FETCH_EDIT_CASE_SUCCESS',
                payload: JSON.parse(config.data)
            })
        })
        .catch((err) => {
            // console.log('transitionCase ERROR:', err.response);
            if (err.response.status === 422) {
                dispatch({
                    type: 'FETCH_EDIT_CASE_ERROR',
                    payload: (err.response && err.response.data && err.response.data.error) || Statuses[500]
                })
            } else if(err?.response?.status == 423) {
              getPatientCaseLock(data.caseCode)(dispatch);     
            } else {
                dispatch({
                    type: 'FETCH_EDIT_CASE_ERROR',
                    payload: Statuses[err.response] || Statuses[0]
                })
            }
        })
};

export const moveCase = (data) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_EDIT_CASE_LOADING',
    });

    const {code} = getState().app.cases.editedCase;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');

    return api.post(`case/move?caseCode=${data.caseCode}&tabTimeStamp=${tabTimeStamp}&stageCode=${data.stageCode}`,
        data)
        .then(({config}) => {
            dispatch({
                type: 'FETCH_EDIT_CASE_SUCCESS',
                payload: JSON.parse(config.data)
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_EDIT_CASE_ERROR',
                payload: Statuses[err.response] || Statuses[0]
            })
            if(err?.response?.status == 423) {
              getPatientCaseLock(data.caseCode)(dispatch);
            }  
        })
};

export const reopenCase = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_REOPEN_CASE_LOADING',
    });

    const {code} = getState().app.cases.editedCase;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');
    const copyCaseCode = shortid.generate();
    return api.post(`case/reopen?caseCode=${code}&tabTimeStamp=${tabTimeStamp}&copyCaseCode=${copyCaseCode}`)
        .then((response) => {
            const reopenedCode = response.data;
            // dispatch({
            //     type: 'FETCH_EDIT_CASE_SUCCESS',
            // })
            if(code !== reopenedCode) {
              history.push('/case/' + reopenedCode);
              window.location.reload();
            }
            dispatch({
                type: 'FETCH_EDIT_CASE_SUCCESS',
                payload: {
                    caseCode: reopenedCode,
                    stageCode: "CW_DISPATCH"
                }
            });
            // Retrieve case values in reopen case
            if(code === reopenedCode) {
                getCaseValues(reopenedCode)(dispatch).then(() => {
                    getCase(reopenedCode)(dispatch, getState);
                });
            } else {
                getCase(reopenedCode)(dispatch, getState)
            }
            fetchCaseCount()(dispatch)
        })
        .catch((error) => {
            console.log(error)
            dispatch({
              type: 'FETCH_CASE_LOADED_SUCCESS',
            });
            dispatch(setCaseMessage({
                type: 'error',
                text: error.response.data,
                time: 5000
            }))
            if(error?.response?.status == 423) {
              getPatientCaseLock(code)(dispatch);
            }   
        })
};

export const reopenCaseFromGrid = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_REOPEN_CASE_LOADING',
    });

    const {selectedCaseCodes} = getState().app.case_list;
    const code = selectedCaseCodes[0];

    return api.post(`case/reopen?caseCode=${code}`)
        .then(() => {
            dispatch({
                type: 'FETCH_REOPEN_CASE_SUCCESS',
                payload: code
            });
            dispatch(setSelectedCases([]))
        })
        .catch(() => {
            dispatch({
                type: 'FETCH_REOPEN_CASE_ERROR',
                payload: 'You cant reopen this case, please check company settings or ask administrator.'
            })
        })
};

export const setSelectedCases = (cases) => {
    return {
        type: 'SET_SELECTED_CASES',
        payload: cases
    }
};

// export const buildCasesList = () => (dispatch, role) => {
//     dispatch({
//         type: 'BUILD_LIST_CASES',
//         payload: role
//     })
// };

export const addVisibleCodes = codes => dispatch => {
    dispatch({
        type: 'ADD_VISIBLE_CODES',
        payload: codes
    })
};

// export const clearVisibleCodes = () => (dispatch) => {
//     dispatch({
//         type: 'CLEAR_VISIBLE_CODES'
//     })
// }

export const removeCaseListError = () => dispatch => {
    dispatch({
        type: 'REMOVE_CASE_LIST_ERROR',
    })
};
