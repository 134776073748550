/* eslint-disable comma-dangle */
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunkMiddleware from 'redux-thunk';
import history, { confirmationMiddleware } from './history';
import reducers from './reducers/index';
import { checkCaseHeartBeat, startCaseTimer, startUseByTimer } from '../utils/helpers';
import autoLogoutMiddleware from './autoLogoutMiddleware';
import changeLocationMiddleware from './changeLocationMiddleware';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './rootEpic';

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const epicMiddleware = createEpicMiddleware();

const pReducer = persistReducer(persistConfig, (state, action) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
        const version = localStorage.getItem('version');
        const shouldRefresh = localStorage.getItem('shouldRefresh');
        localStorage.clear();
        localStorage.setItem('version', version);
        localStorage.setItem('shouldRefresh', shouldRefresh);
    }
    return reducers(state, action);
});

export const store = createStore(
    combineReducers({
        app: pReducer,
        router: connectRouter(history),
    }),
    composeWithDevTools(
        applyMiddleware(
            routerMiddleware(history),
            changeLocationMiddleware,
            autoLogoutMiddleware,
            confirmationMiddleware,
            epicMiddleware,
            thunkMiddleware,
        ),
    ),
);

epicMiddleware.run(rootEpic);

setTimeout(() => {
    startCaseTimer(store);
}, 5000);
setTimeout(() => {
    startUseByTimer(store);
}, 5000);
setTimeout(() => {
  checkCaseHeartBeat(store);
}, 5000);
/*setTimeout(() => {
  checkOrTracking(store);
}, 5000);*/

export const persistor = persistStore(store);
