import React from 'react';
import { connect } from 'react-redux'
import Box from '../../../components/Box'
import BoxHeader from '../../../components/BoxHeader'
import BoxBody from '../../../components/BoxBody'
import BoxFooter from '../../../components/BoxFooter'
import ReviewerTiering from './ReviewerTiering'
import SystemMessage from '../../../components/SystemMessage';
import { dropDownFixPosition } from "../../../utils/helpers";
import ReviewersFilteringNew from "./ReviewersFilteringNew";

class AssignReviewersPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: false
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('wheel', this.handleScroll, { passive: true });
        document.addEventListener('scroll', this.handleScroll);
        document.addEventListener('touchmove', this.handleScroll, { passive: true });
    }

    componentWillUnmount() {
        document.removeEventListener('wheel', this.handleScroll);
        document.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('touchmove', this.handleScroll);
    }

    handleScroll() {
        const { savedButton, savedNext } = this.props;
        if (!this.state.opened &&
            typeof savedButton !== "undefined"
            && typeof savedButton.getBoundingClientRect === "function"
            && savedNext) {

            this.setState({ opened: true });
            let savedClass = savedButton.parentElement.className;
            savedButton.parentElement.className = savedButton.parentElement.className.replace(' open', '');
            setTimeout(() => {
                dropDownFixPosition(savedButton, savedNext);
                savedButton.parentElement.className = savedClass;
                this.setState({ opened: false });
            }, 500);
        }
    }

    render() {
        let caseCode = this.props.match.params.id;
        return (
            <section className="content user-profile-content fontWeight600">
                <SystemMessage shown={this.props.locked} type='warning'
                               message='Editing this case is currently locked. Please, navigate back to the case for further options.'
                               top='65px' />
                <SystemMessage shown={this.props.editedSuccess} type='success'
                               message='Reviewers successfully assigned'
                               top='65px' />
                <SystemMessage shown={this.props.error} type='error'
                               message={this.props.error} top='65px' />
                <Box
                    color="blue"
                    classes="box-profile-first-row-height-show">
                    <BoxHeader
                        title="Reviewer Assignment"
                        color="blue"
                        icon="fa-user-md" />
                    <BoxBody style={{ padding: '0 200px 0 50px' }}>
                        {/*<ReviewersFiltering caseCode={caseCode}> </ReviewersFiltering>*/}
                        <ReviewersFilteringNew />
                        <ReviewerTiering caseCode={caseCode}> </ReviewerTiering>
                    </BoxBody>

                    <BoxFooter>
                    </BoxFooter>
                </Box>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    editedSuccess: state.app.cases.editCaseSuccess,
    error: state.app.case_assign.error,
    savedButton: state.app.case_assign.savedButton,
    savedNext: state.app.case_assign.savedNext,
    locked: state.app.cases.caseLockInfo.locked,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AssignReviewersPage)
