import { capitalizeFirstLetter, formatSizeUnits, getCorrectDateFormat, isJSON } from '../../../utils/helpers';
import { clearCaseSessionStorageData, saveCaseSessionStorageData } from '../../../utils/case-helpers';

export const FETCH_UPDATE_CASE_VALUES_SUCCESS = 'FETCH_UPDATE_CASE_VALUES_SUCCESS';
export const FETCH_UPDATE_CASE_VALUES_SUCCESS_COMMITTED = 'FETCH_UPDATE_CASE_VALUES_SUCCESS_COMMITTED';
export const UPDATE_ASSIGNMENT_SUCCESS = 'UPDATE_ASSIGNMENT_SUCCESS';

const defaultState = {
    loading: false,
    isCaseValuesLoading: false,
    loading_files: false,
    loading_internal: false,
    loading_reports: false,
    data: [],
    caseLockInfo: {
      forceEditable: true,
      locked: true,
      lockedByOtherUser: false
    },
    editedCase: {
        caseFieldUpdateError: false,
        currentStage: { code: '' },
        currentStageCode: '',
        assignmentDetails: [],
        isReOpenableByAllowedDays: true,
        ownersInfo: {
            ownerDraft: { code: 'ownerDraft', action: 'action', stage: 'ownerDraft' },
            ownerDispatch: { code: 'ownerDispatch', action: 'action', stage: 'ownerDispatch' },
            ownerReview: { code: 'ownerReview', action: 'action', stage: 'ownerReview' },
            ownerQa: { code: 'ownerQa', action: 'action', stage: 'ownerQa' }
        },
        fileUploadErrors: []
    },
    pagesCount: 0,
    preparedFields: {},
    preparedAttributes: {},
    moveToStage: '',
    values: {},
    files: [],
    medical_records: [],
    reports: [],
    internal_case_files: [],
    fields: [],
    ref_fields: {},
    sections: [],
    isAllValid: true,
    showNotValidMessage: false,
    caseMessage: '',
    caseMessageType: 'success',
    caseMessageTime: 0,
    caseBackendMessage: '',
    error: '',
    caseFileErrorMessage: '',
    forCheck: {},
    caseValid: undefined,
    reviewersCount: '',
    mergeFile: [{
        name: "Records_",
        pageCount: 0,
        sizeKB: "",
        type: "MEDICAL_RECORDS",
        isMerge: true,
        isBillable: false
    }],
    submitAfterLoad: false,
    submitAfterValidation: false,
    caseIsCreated: false,
    tableWidth: 100,
    internalCaseReportCode: '',
    disabledReviewerButtons: false,
    needToReloadAssignments: false
};

const getFilesData = (filesArray, pagesCount, caseId) => {
    let mergeCount = 0;
    // let pageCountMerge = 0;
    // let sizeMerge = 0;
    let files = filesArray ? filesArray.map((item) => {
        let visible = '';
        if (item.type === 'MEDICAL_RECORDS') {
            if (item.includedToMerge) {
                mergeCount += 1;
                // sizeMerge += item.size;
                // pageCountMerge += item.pageCount;
            }
            if (item.visibleToRoles.indexOf('ROLE_CLIENT_ADMIN') !== -1 && item.visibleToRoles.indexOf('ROLE_REVIEWER') !== -1) visible = 'All';
            else {
                if (item.visibleToRoles.indexOf('ROLE_CLIENT_ADMIN') !== -1) visible = 'Client';
                else if (item.visibleToRoles.indexOf('ROLE_REVIEWER') !== -1) visible = 'Reviewer';
                else visible = 'Staff';
            }
        }
        return {
            ...item,
            key: item.code,
            sizeKB: formatSizeUnits(item.size),
            date_original: item.date,
            date: getCorrectDateFormat(item.date, 'L LT'),
            typeName: capitalizeFirstLetter(item.type.replace("_ATTACHMENT", "")),
            isBillable: item.includedToMerge || item.counted,
            visible: visible
        }
    }) : defaultState.files;
    if (mergeCount > 0 || pagesCount > 0) {
        defaultState.mergeFile[ 0 ][ 'sizeKB' ] = '';//formatSizeUnits(sizeMerge);
        defaultState.mergeFile[ 0 ][ 'pageCount' ] = pagesCount;
        defaultState.mergeFile[ 0 ][ 'name' ] = 'Records_' + caseId + '.pdf';
        defaultState.mergeFile[ 0 ][ 'key' ] = 'Records_' + caseId + '.pdf';
        files = defaultState.mergeFile.concat(files);
    }
    return files;
};

const getFilesTypes = (files) => {
    let medical_records = [];
    let reports = [];
    let internal_case_files = [];
    for (let i = 0, n = files.length; i < n; i += 1) {
        if (files[ i ].type === 'MEDICAL_RECORDS')
            medical_records.push(files[ i ]);
        if (files[ i ].type === 'REPORT')
            reports.push(files[ i ]);
        if (files[ i ].type === 'INTERNAL_CASE_FILE')
            internal_case_files.push(files[ i ]);
    }

    return {
        reports: reports,
        medical_records: medical_records,
        internal_case_files: internal_case_files
    };
};

const getPagesCount = (pagesCount, oldItem) => {
    let result = pagesCount;
    if (oldItem !== 0) {
        if (result > 0) {
            result -= (oldItem.pageCount ? oldItem.pageCount : 0);
            if (result < 0) result = 0;
        }
    }
    return result;
};

const cases = (state = defaultState, action) => {
    // console.log('CASE ALL', action.type);
    switch (action.type) {
        case 'FETCH_CASE_LOADING':
        case 'FETCH_EDIT_CASE_LOADING':
        case 'FETCH_REOPEN_CASE_LOADING':   
            return { ...state, loading: true, error: null };
        case 'FETCH_CASE_TEMPLATE_LOADING':
        case 'FETCH_CASE_ASSIGN_LOADING':
            return { ...state, loading: true, error: null };
        
        case 'GET_CASE_VALUES_LOADING':
            return { ...state, isCaseValuesLoading: true, error: null };    

        case 'FETCH_GET_CASE_FILES_LOADING':
        case 'FETCH_ATTACH_CASE_FILE_LOADING':
        case 'FETCH_DETACH_CASE_FILE_LOADING':
        case 'FETCH_UPDATE_CASE_FILE_LOADING':
            return { ...state, loading_files: true, error: null };

        case 'FETCH_GENERATE_INTERNAL_LOADING':
            return {
                ...state,
                loading_internal: true,
                error: null,
                internalCaseReportCode: ''
            };
        case 'CASE_FIELD_UPDATE_ERROR':
            return Object.assign({}, state, {
              editedCase: {
                  ...state.editedCase,
                  caseFieldUpdateError: true
              },
          });

        case 'FETCH_CASE_LOADED_SUCCESS':
            return { ...state, loading: false };

        case 'FETCH_GENERATE_INTERNAL_SUCCESS':
            return {
                ...state,
                loading_files: false,
                loading_internal: false,
                loading: false,
                internalCaseReportCode: action.payload,
            };

        case 'FETCH_TEMPLATE_FULL_SUCCESS': {
            let fields = [];
            let ref_fields = {};
            let sections = [];
            if (typeof action.payload.fieldInstancesDto !== "undefined") {
                fields = action.payload.fieldInstancesDto;
                fields.sort((a, b) => {
                    if (a.position < b.position) return -1;
                    if (a.position > b.position) return 1;
                    let a_names = a.names != null ? a.names[ 'EN' ] : ' ';
                    let txt1 = isJSON(a_names) ?
                        JSON.parse(a_names).blocks[ 0 ].text : a_names;
                    let b_names = b.names != null ? b.names[ 'EN' ] : ' ';
                    let txt2 = isJSON(b_names) ?
                        JSON.parse(b_names).blocks[ 0 ].text : b_names;
                    if (txt1 > txt2) return -1;
                    if (txt1 < txt2) return 1;
                    return 0;
                });
                for (let f_ind = 0; f_ind < fields.length; f_ind += 1) {
                    let field = fields[ f_ind ];
                    if (field.fieldType.indexOf('REF_') === 0) {
                        ref_fields[ field.code ] = 'list_' + field.fieldType.substr(4).toLowerCase();
                    }
                }
                saveCaseSessionStorageData(action.caseCode, 'ref_fields', ref_fields);
            }
            if (typeof action.payload.sectionDto !== "undefined") {
                sections = action.payload.sectionDto;
                sections.sort((a, b) => {
                    if (a.position < b.position) return -1;
                    if (a.position > b.position) return 1;
                    let txt1 = a.displayName[ 'EN' ];
                    let txt2 = b.displayName[ 'EN' ];
                    if (txt1 > txt2) return -1;
                    if (txt1 < txt2) return 1;
                    return 0;
                });
            }
            return {
                ...state,
                loading: false,
                ref_fields: ref_fields,
                fields: fields,
                sections: sections
            };
        }
        case 'FETCH_GET_CASE_SUCCESS': {
            const fileUploadErrors = state.editedCase.code === action.payload.code ? state?.editedCase.fileUploadErrors : [];
            if (typeof state.editedCase.code !== "undefined" && action.payload.code !== state.editedCase.code)
                clearCaseSessionStorageData(action.payload.code);

            saveCaseSessionStorageData(action.payload.code, 'edited_case', action.payload);
            let pagesCount = 0;
            if (typeof action.payload.pagesCount !== "undefined" && action.payload.pagesCount != null) {
                pagesCount = action.payload.pagesCount;
            }

            return {
                ...state,
                error: null,
                loading: false,
                isAllValid: true,
                pagesCount: pagesCount,
                preparedAttributes: {},
                editedCase: action.payload ? {
                    ...action.payload,
                    caseFieldUpdateError: defaultState.editedCase.caseFieldUpdateError,
                    filteredReviewers: [],
                    ownersInfo: defaultState.editedCase.ownersInfo,
                    fileUploadErrors: fileUploadErrors,
                } : defaultState.editedCase,
            };
        }
        case 'CLEAR_EDITED_CASE':
            if (typeof state.editedCase.code !== "undefined") clearCaseSessionStorageData(state.editedCase.code);
            return {
                ...state,
                editedCase: defaultState.editedCase
            };

        case 'FETCH_GET_CASE_FILES_SUCCESS': {
            let files = getFilesData(action.payload, action.pagesCount, action.caseId);
            const { reports, medical_records, internal_case_files } = getFilesTypes(files);
            return {
                ...state,
                error: null,
                loading_files: false,
                files: files,
                reports: reports,
                medical_records: medical_records,
                internal_case_files: internal_case_files,
                pagesCount: action.pagesCount,
            };
        }
        case 'FETCH_CHANGE_MERGE_ON_DETACH_FILE':
            return {
                ...state,
                error: null,
                loading_files: false,
                pagesCount: getPagesCount(action.payload.pagesCount, action.payload.oldItem)
            };
        case 'GET_PATIENT_CASE_LOCK_INFO':
          return Object.assign({}, state, {
            // loading:false,
            error: null,
                ...state,
                caseLockInfo: Object.assign({}, defaultState.caseLockInfo, action.payload)
          });
        case FETCH_UPDATE_CASE_VALUES_SUCCESS: {
            let values = state.values;

            if (action.payload && action.payload[ 'fieldData' ]) {
                if (typeof values[ 'fieldData' ] === "undefined") {
                    values.fieldData = {};
                }
                for (let key in action.payload[ 'fieldData' ]) {
                    if (!Object.prototype.hasOwnProperty.call(action.payload[ 'fieldData' ], key)) continue;
                    values[ 'fieldData' ][ key ] = action.payload.fieldData[ key ];
                }
                if (action.payload.code) {
                    saveCaseSessionStorageData(action.payload.code, 'values_save', values);
                }
            }
            return {
                ...state,
                error: null,
                loading: false,
                values: values
            };
        }
        case 'FETCH_GET_CASE_VALUES_SUCCESS':
            if (action.payload && action.payload.code) {
                saveCaseSessionStorageData(action.payload.code, 'values_save', action.payload);
            }
            return {
                ...state,
                error: null,
                isCaseValuesLoading: false,
                loading: false,
                values: action.payload ? {
                    ...action.payload
                } : defaultState.values
            };
              
        case 'REFRESH_LOCAL_STORAGE':
            if (action.payload && action.payload.code) {
                saveCaseSessionStorageData(action.payload.code, 'values_save', action.payload);
            }
            return {
              ...state,
              error: null,
              isCaseValuesLoading: false,
              loading: false
          };  

        case 'FETCH_GET_OWNERS_PROFILES_SUCCESS':
            return Object.assign({}, state, {
                // loading:false,
                error: null,
                editedCase: {
                    ...state.editedCase,
                    ownersInfo: Object.assign({}, defaultState.editedCase.ownersInfo, action.payload)
                },
            });

        case 'FETCH_CHANGE_STAGE_OWNER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editedCase: {
                    ...state.editedCase,
                    [ action.stage ]: action.payload.code,
                    ownersInfo: {
                        ...state.editedCase.ownersInfo,
                        [ action.stage ]: action.payload
                    }
                },
            });
        case 'FETCH_DELETE_STAGE_OWNER_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                editedCase: {
                    ...state.editedCase,
                    [ action.payload ]: null,
                    ownersInfo: {
                        ...state.editedCase.ownersInfo,
                        [ action.payload ]: defaultState.editedCase.ownersInfo[ action.payload ]
                    }
                },
            });

        // case 'FETCH_UPDATE_ACTION_SUCCESS':
        //     let assignmentDetails = state.editedCase.assignmentDetails.map((item) => {
        //         if (item.code === action.payload.code) item.action = action.payload.action;
        //         return item
        //     });
        //     return Object.assign({}, state, {
        //         loading: false,
        //         error: null,
        //         caseIsCreated: false,
        //         accepted: assignmentDetails.find((item) => item.action === 'ACCEPTED') || {},
        //         editedCase: {
        //             ...state.editedCase,
        //             assignmentDetails: assignmentDetails
        //         }
        //     });

        case 'FETCH_GENERATE_INTERNAL_ERROR':
            return {
                ...state,
                caseFileErrorMessage: action.payload && action.payload.message,
                loading: false,
                loading_files: false,
                loading_internal: false
            };

        case 'FETCH_EDIT_CASE_ERROR': {
            // console.log('CASE FETCH_EDIT_CASE_ERROR', state);
            return { ...state, loading: false, loading_files: false, loading_internal: false, caseBackendMessage: action.payload };
        }
        case 'FETCH_CASE_ERROR':
        case 'FETCH_GET_CASE_FILES_ERROR':
        case 'FETCH_CASE_FILE_ERROR':
        case 'FETCH_CASE_ASSIGN_ERROR':
        case 'FETCH_REOPEN_CASE_ERROR':
            return { ...state, loading: false, loading_files: false, loading_internal: false, isCaseValuesLoading: false };

        case 'UPLOAD_FILES_ERROR':
            return {
              ...state,
              loading: false,
              loading_files: false,
              loading_internal: false,
              editedCase: {
                ...state.editedCase,
                fileUploadErrors: action.payload.fileUploadErrors,
              }
            };
        
        case 'CLEAR_FILES_ERROR':
            return {
              ...state,
              editedCase: {
              ...state.editedCase,
              fileUploadErrors: [],
              }
            };     

        case 'EDIT_CASE_FIELD':
            return Object.assign({}, state, {
                editedCase: Object.assign({}, state.editedCase, action.payload)
            });

        case UPDATE_ASSIGNMENT_SUCCESS:
            return Object.assign({}, state, {
                loading: false
            });

        case 'PREPARE_CASE_FIELDS':
            return Object.assign({}, state, {
                preparedFields: action.payload
            });
        case 'PREPARE_CASE_ATTRIBUTES':
            return Object.assign({}, state, {
                preparedAttributes: action.payload
            });

        case 'SUBMIT_AFTER_LOAD':
            return Object.assign({}, state, {
                submitAfterLoad: action.payload,
                loading: false
            });
        case 'SUBMIT_AFTER_VALIDATION':
            return Object.assign({}, state, {
                submitAfterValidation: action.payload
            });

        case 'EDIT_CASE_STAGE_FIELD':
            return Object.assign({}, state, {
                moveToStage: action.payload
            });

        case 'VALIDATE_CASE_FIELD':
            return Object.assign({}, state, {
                isAllValid: action.payload
            });
        case 'SHOW_NOT_VALID_CASE':
            return Object.assign({}, state, {
                showNotValidMessage: action.payload
            });
        case 'SET_CASE_MESSAGE':
            return Object.assign({}, state, {
                caseMessage: action.payload.text,
                caseMessageType: action.payload.type,
                caseMessageTime: action.payload.time
            });
        case 'CLEAR_CASE_MESSAGES':
            return Object.assign({}, state, {
                caseMessage: '',
                caseBackendMessage: '',
                caseFileErrorMessage: ''
            });
        case 'FETCH_GET_CASE_FOR_CHECK':
            return Object.assign({}, state, {
                forCheck: action.payload
            });
        case 'CASE_IS_CREATED':
            return Object.assign({}, state, {
                caseIsCreated: action.payload
            });

        case 'SAVE_TABLE_WIDTH':
            return Object.assign({}, state, {
                tableWidth: action.payload
            });

        case 'DISABLE_REVIEWER_BUTTONS':
            return Object.assign({}, state, {
                disabledReviewerButtons: action.payload
            });
        case 'SET_NEED_RELOAD_ASSIGNMENTS':
            return Object.assign({}, state, {
                needToReloadAssignments: action.payload
            });

        default:
            return state
    }
};

export default cases
