import React, { Fragment, useContext } from 'react';
import Control from 'components/Form/Control';
import { Col, ControlLabel } from 'react-bootstrap';
import CurrencyInput from 'components/Controls/CurrencyInput';
import './FinanceSection.css';
import { startCase } from 'lodash';
import FinanceContext from './FinanceContext';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

const LABEL_COL = 2;
const INPUT_COL = 6;

// eslint-disable-next-line react/display-name
export const formatReadonly = (currency = '') => (value) => (
  <div className="financeSection__readonly">
    <span>
      {value} {currency}
    </span>
  </div>
);

const AmountField = ({
  isClosedCase,
  isLocked,
  name,
  labelVisibilityDependsOn = null,
  hideLabel = false,
  overrideLabel,
  single = false,
}) => {
  const {
    fieldSettings,
    fieldVisibilites,
    calculateUpdateSuccess,
    handleChange,
    isLoading,
    legacyValidator,
    templateCode,
  } = useContext(FinanceContext);

  const {
    [name]: { name: labelFromName, isEditable, unit, isRequired } = {
      name: startCase(name),
      unit: '',
      isEditable: false,
      isRequired: false,
    },
  } = fieldSettings;

  const showLabel =
    (!hideLabel && fieldVisibilites[name]) ||
    fieldVisibilites[labelVisibilityDependsOn];


  const { [name]: fieldValueForLegacyValidator } = useSelector(
    (state) => state.app.finances.entity,
  );

  const label = overrideLabel ? overrideLabel : startCase(labelFromName);

  let tickMark = calculateUpdateSuccess ? ' ✔' : ' ❌';
  let color = calculateUpdateSuccess ? 'green' : 'red';
  let tickMarkHtml = calculateUpdateSuccess != null && <span style={{ color }}>{tickMark}</span>;

  return (
    <>
      {showLabel && (
        <Col md={single ? 5 : LABEL_COL}>
          <ControlLabel className="financeSection__amountField-label">
            {overrideLabel ? overrideLabel : startCase(label)}
            {tickMarkHtml}
          </ControlLabel>
        </Col>
      )}

      {fieldVisibilites[name] && (
        <>
          <span style={{ display: 'none' }}>
            {isEditable &&
              isRequired &&
              legacyValidator.message(
                label,
                fieldValueForLegacyValidator,
                'required|numeric',
              )}
          </span>
          <Col md={hideLabel ? LABEL_COL : INPUT_COL} >
            <Control
              name={name}
              id={name + '_' + templateCode}
              currency={unit}
              readOnly={!isEditable && !isLoading}
              formatReadonlyValue={formatReadonly(unit)}
              onChange={handleChange}
              component={CurrencyInput}
              container={Fragment}
              disabled={isLoading || isClosedCase || isLocked}
              isLoading={isLoading}
              rules={isEditable ? 'numericOrEmpty' : undefined}
              className={classnames([
                'financeSection__field',
                { required: isRequired },
              ])}
            />
          </Col>
        </>
      )}
    </>
  );
};

export { INPUT_COL, LABEL_COL };

export default AmountField;
