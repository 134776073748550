import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { createAssignmentsTiers } from "../../../../redux/actions/cases/caseAssignActions"
import Spinner from "../../../Develop/Spinner";

class CreateTiersButton extends React.Component {
    constructor(props) {
        super(props);
        this.onClickCreateTiers = this.onClickCreateTiers.bind(this);
    }

    onClickCreateTiers() {
        this.props.createAssignmentsTiers();
    }

    render() {
        const { loading, locked } = this.props;
        return <button
            color='blue'
            className="btn btn-primary margin-left-5"
            style={{ width: '100px', display: 'inline-flex', justifyContent: 'center' }}
            disabled={loading || locked}
            onClick={this.onClickCreateTiers}
            title='Create Tiers'
        >
            <span>{'Create Tiers'}</span>&nbsp;{loading && <Spinner />}
        </button>
    }
}

CreateTiersButton.contextTypes = {
    translate: PropTypes.func
};

const mapStateToProps = (state) => ({
    loading: state.app.case_assign.loading,
    locked: state.app.cases.caseLockInfo.locked,
});

const mapDispatchToProps = dispatch => ({
    createAssignmentsTiers: () => dispatch(createAssignmentsTiers())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTiersButton)
