import connect from "react-redux/es/connect/connect";
import RemoveButton from './RemoveButton'
import {removeCase} from '../../../redux/actions/cases/caseActions'


const mapState = (state) => {
    return {
        className: 'btn-default margin-left-5',
        isVisible: state.app.cases.editedCase.currentStage.code === 'CW_DRAFT' && state.app.auth.user.roleCode !== 'ROLE_REVIEWER',
        icon: 'fa-remove',
        title: 'Remove',
        needConfirmation: true,
        isLocked: state.app.cases.caseLockInfo.locked,
    }
};

const mapDispatchToProps = dispatch => {
    const response =  {
        onClick: () => dispatch(removeCase()),
    }

  return response;
};

export default connect(mapState, mapDispatchToProps)(RemoveButton);
