import { logout, setCurrentUser } from '../redux/actions/authActions';
import { updateCaseValues, caseHeartBeat } from '../redux/actions/cases/caseActions';
import { getCasesActivities } from '../redux/actions/cases/caseListActions';
import { GLOBAL_ASYNC_CACHE_TIME, LOCAL_TIMEZONE_OFFSET, reducedRoleStageMatrix } from './Constants';
import isEqual from 'lodash.isequal';
import { STAGE_CODE } from '../constants/stageCodes';
import TextTooltip from "../components/TextTooltip";
import React from "react";

let moment = require('moment');

function curry(fn) {
    const arity = fn.length;

    return function $curry(...args) {
        if (args.length < arity) {
            return $curry.bind(null, ...args);
        }

        return fn.call(null, ...args);
    };
}

function sortFunc(attr, isReverse, prev, next) {
    return prev[attr] - next[attr];
}

export const sortBy = curry(sortFunc);

export const alphabetSorter = (a, b) => {
    if (typeof a.label === 'undefined') {
        if (typeof a.value !== 'undefined') a.label = a.value;
        else return 0;
    }
    if (typeof b.label === 'undefined') {
        if (typeof b.value !== 'undefined') b.label = b.value;
        else return 0;
    }
    if (typeof a.label !== 'string' || typeof b.label !== 'string') {
        return 0;
    }
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    return 0;
};

export function idFromString(string) {
    // string = string.replace(/_/, ' ');
    // return string.replace(/^\w/, c => c.toUpperCase());
    return string
        .split('')
        .map((item) => item.charCodeAt(0))
        .join('');
}

export function capitalizeFirstLetter(string) {
    if (typeof string !== 'string') return '';

    return string
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
        .replace(/_/, ' ')
        .replace(/-/, ' ');
}

export function tierFormatter(tier) {
    switch (tier) {
        case 'TIER_1':
            return 'Tier 1';
        case 'TIER_2':
            return 'Tier 2';
        case 'TIER_3':
            return 'Tier 3';
        default:
            return '';
    }
}

export function capitalizeAllFirstLetters(string) {
    string = string.toLowerCase();
    string = string.replace(/_/g, ' ');
    let arr = string.split(' ');
    let result = '';
    arr.forEach((item) => {
        result = result + ' ' + item.charAt(0).toUpperCase() + item.slice(1);
    });
    return result.slice(1);
}

const addParam = (url, key, value) => {
    if (url.indexOf('?') === -1) {
        return url + '?' + key + '=' + value;
    } else {
        return url + '&' + key + '=' + value;
    }
};

export const getParamFromUrl = (url) => {
    let newUrl = url.split('').reverse().join('');
    return newUrl.slice(0, newUrl.indexOf('/')).split('').reverse().join('');
};

export const makeUrlFromType = (str) => {
    return str.replace(/_/g, '/');
};

export function urlBuild(loadParams) {
    let url = loadParams.url;
    if (url[0] !== '/') {
        url = '/' + url;
    }

    if (loadParams.page) {
        url = addParam(url, 'page', loadParams.page);
    }

    if (loadParams.filters) {
        for (let key in loadParams.filters) {
            if (!loadParams.filters.hasOwnProperty(key)) continue;
            url = addParam(url, key, loadParams.filters[key]);
        }
    }

    if (loadParams.sort) {
        for (let col of loadParams.sort) {
            url = addParam(url, `order[${col.key}]`, col.direction);
        }
    }
    return url;
}

export const isAdmin = (code) => /ADMIN/g.test(code);
export const isStaff = (code) => /STAFF/g.test(code);
export const isClient = (code) => /CLIENT/g.test(code);
export const isReviewer = (code) => /REVIEWER/g.test(code);
export const isUser = (code) => /CLIENT/g.test(code);

export const userCanSeeFinance = (role, stage) => {
    if (isReviewer(role) && stage.code === STAGE_CODE.REVIEW) {
        return true;
    }

    if ((isAdmin(role) || isStaff(role)) && [STAGE_CODE.DISPATCH, STAGE_CODE.REVIEW, STAGE_CODE.QA, STAGE_CODE.CLOSED].includes(stage.code)) {
        return true;
    }

    return false;
};

// TODO: add role if need in the future
export const userCanSeeComments = (code) => {
    return true;
};

export const getRoleId = (roleCode) => {
    if (isClient(roleCode)) return 1;
    if (isStaff(roleCode)) return 2;
    if (isReviewer(roleCode)) return 3;
};

export function startSessionTimer(store) {
    const MINUTES_UNTIL_AUTO_LOGOUT = 20;
    let reloaded = false;
    document.body.addEventListener('click', () => resetTimer());
    document.body.addEventListener('mouseover', () => resetTimer());
    document.body.addEventListener('mouseout', () => resetTimer());
    document.body.addEventListener('keydown', () => resetTimer());
    document.body.addEventListener('keyup', () => resetTimer());
    document.body.addEventListener('keypress', () => resetTimer());

    function emptyUser() {
        console.log('LOGOUT!');
        store.dispatch(logout({}));
    }

    function goToLastVisited(userCode) {
        if (store.getState().app && store.getState().app.auth && store.getState().app.auth.logoutPath) {
            if (typeof store.getState().app.auth.logoutPath[userCode] !== 'undefined')
                window.location.href = '/#' + store.getState().app.auth.logoutPath[userCode];
            else window.location.href = '/';
        }
    }

    function getLastAction() {
        return parseInt(localStorage.getItem('lastAction'), 10);
    }

    function setLastAction(lastAction) {
        localStorage.setItem('lastAction', lastAction.toString());
    }

    function isAuthHref() {
        return window.location.href.indexOf('/login') > -1 || window.location.href.indexOf('/link/') > -1;
    }

    function resetTimer() {
        if (!reloaded) {
            let lastAction = getLastAction();
            let currentUser = localStorage.getItem('current_user');
            if (typeof currentUser === 'undefined' || currentUser === '{}') {
                if (!isAuthHref()) {
                    reloaded = true;
                    setInterval(() => {
                        reloaded = false;
                    }, 5000);
                    store.dispatch(logout({}));
                }
            } else {
                if (isAuthHref() && currentUser) {
                    store.dispatch(setCurrentUser(JSON.parse(currentUser)));
                    goToLastVisited(JSON.parse(currentUser).code);
                }
                const now = Date.now();
                const timeLeft = lastAction + MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;
                const diff = timeLeft - now;
                const isTimeout = diff < 0;
                if (isTimeout) {
                    emptyUser();
                } else {
                    setLastAction(now);
                }
            }
        }
    }
}

export function startCaseTimer(store) {
    setInterval(() => {
      if(!isTheCurrentUserAssignedReviewer(store) && !store.getState().app.cases.caseLockInfo.locked &&
       window.location.href.includes(store.getState().app.cases.editedCase.code)
        && sessionStorage.getItem('c__' + store.getState().app.cases.editedCase.code)) {
        console.log('TICK');
        store.dispatch(updateCaseValues(store.getState().app.cases.editedCase.code, true));
      }
  }, 3000);
}

export function checkCaseHeartBeat(store) {
  setInterval(() => {
    if(!isTheCurrentUserAssignedReviewer(store) && !store.getState().app.cases.caseLockInfo.locked &&
     window.location.href.includes(store.getState().app.cases.editedCase.code)
      && sessionStorage.getItem('c__' + store.getState().app.cases.editedCase.code)) {
      console.log('TICK-case heart beat');
      store.dispatch(caseHeartBeat(store.getState().app.cases.editedCase.code));
    }
}, 20000);
}

function isTheCurrentUserAssignedReviewer(store) {
  const isInrReviewStage = store.getState().app.cases.editedCase.currentStage.code === 'CW_REVIEW'
  if(!isInrReviewStage) {
    return false;
  }     
  let aDetails = store.getState().app.case_assign.appliedReviewers;

  if (!aDetails || aDetails.length === 0) {
    return true;
  }

  for (let i = 0; i < aDetails.length; i = i + 1) {
      if (aDetails[ i ].reviewerCode === store.getState().app.auth.user.code && aDetails[ i ].action === 'ASSIGNED') {
          return true;
      }
  }
  return false;
}

/*export function checkOrTracking(store) {
  setInterval(() => {
    const isTrackedTab = sessionStorage.getItem('openReplayScriptLoaded');
    if(!isTrackedTab) {
      loadOpenReplayScript(store.getState().app.auth.user.email);
    }
}, 3000);
}*/

/*export function loadOpenReplayScript(email) {
  const event = new CustomEvent('loadOpenReplayScript', {
    detail: { email: email }
  });
  document.dispatchEvent(event);
  sessionStorage.setItem('openReplayScriptLoaded', true);
}*/

export function startUseByTimer(store) {
    setInterval(() => {
        store.dispatch(getCasesActivities());
    }, 10500);
}

export function getSelectFieldValue(value) {
    switch (typeof value) {
        case 'string':
            return value;
        case 'object':
            return value == null ? null : value.value;
        default:
            return value;
    }
}

export const getObjKey = curry((field) => (obj) => obj[field]);

export const isArrayConverge = (arrayOne, arrayTwo) => {
    if (!(Array.isArray(arrayOne) && Array.isArray(arrayTwo))) return;
    return arrayOne.filter((item) => arrayTwo.indexOf(item) !== -1).length !== 0;
};

export function getMultiSelectValue(value) {
    if (Array.isArray(value)) {
        return value.map((item) => (item != null && item.value ? item.value : item));
    } else if (value && typeof value === 'object') {
        return [value.value];
    } else return [];
}

export function makeSelectOptions(arr) {
    if (Array.isArray(arr)) {
        return arr.map((item) => ({ value: item, label: capitalizeAllFirstLetters(item) }));
    }
    return [];
}

export const isFieldTypeSelect = (field) => {
    if (!field) return;
    return /REF/g.test(getSelectFieldValue(field)) || /select/g.test(getSelectFieldValue(field));
};

export const getNameFromCode = (code, entityList) => {
    if (!Array.isArray(entityList)) return '';
    let entity = entityList.find((entity) => entity.code === code);
    return entity ? entity.name : '';
};

export const getFullNameFromCode = (code, entityList) => {
    let entity = entityList.find((entity) => entity.code === code);
    return entity ? entity.fullName : '';
};

export const getLanguagedNameFromCode = (code, entityList, language) => {
    if (code == null) return '';
    let entity = entityList.find((entity) => entity.code === code);
    if (entity) {
        if (entity.displayName) {
            return entity.displayName[language] ? entity.displayName[language] : '';
        }
        if (entity.names) {
            return entity.names[language] ? entity.names[language] : '';
        }
    }
    return ' ';
};

export const getNamesFromCodes = (codes, entityList) => {
    if (Array.isArray(codes) && Array.isArray(entityList))
        return codes.map((code) => getNameFromCode(code, entityList) || getFullNameFromCode(code, entityList)).join(', ');
    else return '';
};

export const getNamesFromCodesSort = (codes, entityList) => {
    if (Array.isArray(codes) && Array.isArray(entityList)) {
        let result = codes.map((code) => getNameFromCode(code, entityList) || getFullNameFromCode(code, entityList));
        result.sort();
        return result.join(', ');
    } else return '';
};

export const editOrPush = (arr, obj, uniqKey) => {
    const existing = arr.find((item) => item[uniqKey] === obj[uniqKey]);
    if (existing) {
        return arr.map((item) => (item[uniqKey] === obj[uniqKey] ? obj : item));
    } else {
        console.log('dont', [...arr, obj]);
        return [...arr, obj];
    }
};

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const isInteger = (num) => {
    return (num ^ 0) === num;
};

export const convertStyledText = (text) => {
    if (!text) return;

    if (!isJson(text)) return text;
    return JSON.parse(text).blocks
        ? JSON.parse(text)
              .blocks.map((block) => (!block.text.trim() && '\n') || block.text)
              .join('\n')
        : '';
};

export const fromBigToSmallRoleMatrix = (bigMatrix, smallMatrix = reducedRoleStageMatrix) => {
    let resultMatrix = { ...smallMatrix };

    for (let small in smallMatrix) {
        for (let big in bigMatrix) {
            if (!bigMatrix.hasOwnProperty(big)) continue;
            let regExp = new RegExp(small, 'g');
            if (regExp.test(big)) resultMatrix[small] = bigMatrix[big];
        }
    }
    return resultMatrix;
};

export const fromSmallToBigRoleMatrix = (smallMatrix, bigMatrix) => {
    let resultMatrix = { ...bigMatrix };

    for (let big in bigMatrix) {
        if (!bigMatrix.hasOwnProperty(big)) continue;
        for (let small in smallMatrix) {
            if (!smallMatrix.hasOwnProperty(small)) continue;
            let regExp = new RegExp(small, 'g');
            if (regExp.test(big)) resultMatrix[big] = smallMatrix[small];
        }
    }
    return {
        ...resultMatrix,
        'CW_DISPATCH#ROLE_ADMIN': smallMatrix['CW_DISPATCH#ROLE_STAFF'],
        'CW_REVIEW#ROLE_ADMIN': smallMatrix['CW_REVIEW#ROLE_STAFF'],
        'CW_QA#ROLE_ADMIN': smallMatrix['CW_QA#ROLE_STAFF'],
        'CW_DRAFT#ROLE_ADMIN': smallMatrix['CW_DRAFT#ROLE_STAFF'],
        'CW_CLOSED#ROLE_ADMIN': smallMatrix['CW_CLOSED#ROLE_STAFF'],
    };
};

export const fromMatrixToString = (matrix, isAbbreviate = false) => {
    matrix = fromBigToSmallRoleMatrix(matrix);
    let resultString = '';
    let result = {
        staff: [],
        client: [],
        reviewer: [],
    };

    const makePrettyStage = (role) => role.toLowerCase().replace('cw', '').replace(/_/g, '');

    for (let key in matrix) {
        const [stage, role] = key.split('#');
        switch (role) {
            case 'ROLE_STAFF':
                matrix[key] && result.staff.push(makePrettyStage(stage));
                break;
            case 'ROLE_CLIENT':
                matrix[key] && result.client.push(makePrettyStage(stage));
                break;
            case 'ROLE_REVIEWER':
                matrix[key] && result.reviewer.push(makePrettyStage(stage));
                break;
            default:
        }
    }

    for (let key in result) {
        if (result[key].length) {
            if (isAbbreviate) {
                resultString += `${prepareDisplayMatrix(key, result)}`;
            } else {
                resultString += `${capitalizeFirstLetter(key)}(${result[key].join(', ')}); `;
            }
        }
    }
    return resultString;
};

export const getCorrectDateFormat = (utcDate, format) => {
    let result = '';
    if (utcDate) {
        // console.log(utcDate);
        // eliminate deprecation warning for moment
        const m = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4} [0-9]{2}:[0-9]{2}/.test(utcDate)
            ? moment(utcDate, 'MM/DD/YYYY HH:mm').utc(false)
            : moment(utcDate).utc(false);
        result = m.format(format ? format : 'L LT');
    }
    return result;
};

export const getDateTimeFormat4Field = (fieldType, fieldValue) => {
    let result = '';
    if (fieldValue !== '' && typeof fieldValue !== 'undefined' && fieldValue != null) {
        let tmp = getCorrectDateFormat(fieldValue);
        if (tmp.indexOf('Invalid') !== -1) {
            result = parseInt(fieldValue, 10);
        }
        if (fieldType === 'DATE_TIME' || fieldType === 'TIME') {
            result = getCorrectDateFormat(fieldValue, 'L HH:mm');
        } else if (fieldType === 'DATE') {
            result = getCorrectDateFormat(fieldValue, 'L');
        } else if (fieldType === 'TIME_READONLY') {
            result = getCorrectDateFormat(fieldValue, 'HH:mm');
        }
        if (result.indexOf('Invalid') !== -1) {
            result = '';
        }
    }
    // console.log(`%c->getDateTimeFormat4Field[${fieldType}] ${fieldValue} => ${result}`, 'color: green');
    return result;
};

export const isJSON = (str) => {
    try {
        let val = JSON.parse(str);
        return val instanceof Array || val instanceof Object;
    } catch (e) {
        return false;
    }
};

function normalizeCollection({ ...object }) {
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            if (object[key] instanceof Array) {
                object[key] = getMultiSelectValue(object[key]);
            } else if (object[key] instanceof Object) {
                object[key] = object[key].value ? object[key].value : JSON.stringify(object[key]);
            }

            if (!object[key] && typeof object[key] !== 'boolean') object[key] = undefined;
            if (typeof object[key] === 'number') object[key] = object[key] + '';
        }
    }
    return object;
}

/*function getObjectDiff(obj1, obj2) {
    return Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }

        return result;
    }, Object.keys(obj2));
}*/

export const isObjectsEqual = (editedObj, originalObj) => {
    return isEqual(normalizeCollection(editedObj), normalizeCollection(originalObj));
};

export const convertReceivedField = (data) => {
    let defaultValue;
    if (!data || !data.fieldType) return data;

    data = {
        ...data,
        requiredMatrix: fromBigToSmallRoleMatrix(data.requiredMatrix, reducedRoleStageMatrix),
        visibilityMatrix: fromBigToSmallRoleMatrix(data.visibilityMatrix, reducedRoleStageMatrix),
        readOnlyMatrix: fromBigToSmallRoleMatrix(data.readOnlyMatrix, reducedRoleStageMatrix),
        skippDisplayName: !data.name,
        listOptions: data.listOptions && typeof data.listOptions === 'string' ? makeSelectOptions(data.listOptions.split(',')) : [],
    };

    if (data.fieldType === 'BOOLEAN')
        return {
            ...data,
            defaultValue: data.defaultValue === 'false' ? false : !!data.defaultValue,
        };

    if (isFieldTypeSelect(data.fieldType)) {
        defaultValue = data.allowMultiSelect ? JSON.parse(data['defaultValue']) : getSelectFieldValue(data['defaultValue']);
        return { ...data, defaultValue };
    }

    return data;
};

export const validateListOptions = (options, dataType) => {
    // eslint-disable-next-line no-unused-vars
    let rule, message;

    if (!options || !options.length) return true;

    switch (dataType) {
        case 'DATE':
        case 'DATE_TIME':
            return options.reduce((p, option) => p && !(new Date(option.value) + '' === 'Invalid Date' || option.value.length < 8), true);
        case 'NUMBER':
        case 'SCORING':
            rule = /^\d+.?\d*$/;
            message = 'Option must be a valid number';
            break;
        case 'PHONE_NUMBER':
            rule = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
            message = 'Option must be a valid number';
            break;
        case 'INTEGER':
        case 'YEAR':
            rule = /^\d+$/;
            message = 'Option must be an integer number';
            break;
        case 'BOOLEAN':
            rule = /^true$|^false$/;
            message = 'Option must be true or false';
            break;
        default:
            rule = /.+/;
            // eslint-disable-next-line no-unused-vars
            message = 'Option must be a valid string'; // TODO: wtf?
    }

    return options.reduce((p, option) => p && rule.test(option.value), true);
};

export const toArray = (val) => (val ? [val] : []);

export const getFirstValue = (array) => {
    if (!array || !array.length) return null;
    return array[0];
};

export const isThisMonthDate = (date) => {
    if (!date && typeof date !== 'string') return;
    let d = new Date(date);
    let today = new Date();
    return d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear();
};

export const isLastMonthDate = (date) => {
    if (!date && typeof date !== 'string') return;
    let d = new Date(date);
    let today = new Date();
    return (
        (d.getMonth() + 1 === today.getMonth() && d.getFullYear() === today.getFullYear()) ||
        (d.getMonth() === 12 && today.getMonth() === 1 && d.getFullYear() + 1 === today.getFullYear())
    );
};

export const testIp = (ipList) => {
    let result = true;
    // eslint-disable-next-line max-len
    const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    for (let i = 0; i < ipList.length; i++) {
        let ip = ipList[i];
        if (ip.split('-').length > 1) {
            result = testIp(ip.split('-'));
        } else {
            result = ipPattern.test(ip.trim());
        }
    }
    return result;
};

export function camelize(string) {
    if (!string) return;

    string = string.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase();
    });
    return string.charAt(0).toLowerCase() + string.substring(1);
}

export const formatSizeUnits = (bytes) => {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export const reformatCurrency = (value) => {
    if (value) {
        return parseFloat(value) ? parseFloat(value).toFixed(2) : '';
    } else return '';
};

export function dropDownFixPosition(button, dropdown) {
    try {
        // const button = e;//e.target;
        // const dropdown = e.nextSibling;//e.target.nextSibling;

        const rect = button.getBoundingClientRect();
        const dropDownTop = rect.top + 35;
        dropdown.style.top = dropDownTop + 'px';
        dropdown.style.left = rect.left + 'px';
        dropdown.style.position = 'fixed';
    } catch (err) {
        console.group();
        console.log('Error occurred: can not fix dropdown position', err);
        console.groupEnd();
    }
}

export function getTimestampAccordingOffset(value) {
    const timestamp = new Date(value).getTime();
    return new Date(timestamp + LOCAL_TIMEZONE_OFFSET).getTime();
}

// export function addOffset(value) {
//     const offset = LOCAL_TIMEZONE_OFFSET;
//     const timestamp = new Date(value).getTime();
//
//     return new Date(timestamp - offset).getTime();
// }

export const isEntityLoaded = (entity, cacheTime = GLOBAL_ASYNC_CACHE_TIME) =>
    entity && entity.lastLoadedTime && Date.now() - entity.lastLoadedTime < cacheTime;

export const toolTipId = (prefix) => {
    return `tooltip-${prefix}-${Math.round(Math.random() * 1000000)}`;
};

function prepareDisplayMatrix(key, result) {
  const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);
  const name = capitalizeFirstLetter(key).charAt(0);
  const resultArr = result[key];

  const defaultResults = {
      draft: '.',
      dispatch: '.',
      review: '.',
      qa: '.',
      closed: '.'
  };

  const results = resultArr.reduce((acc, item) => {
      if (acc.hasOwnProperty(item)) {
          acc[item] = 'X';
      }
      return acc;
  }, {...defaultResults});

  return `${name} ${Object.values(results).join('')} `;
}


export function titleFormatter (cell, row) {
    let formattedText = null;

    if (cell === row.visibilityMatrix) {
        formattedText = row.formattedVisibilityMatrix;
    } else if (cell === row.readOnlyMatrix) {
        formattedText = row.formattedReadOnlyMatrix;
    } else if (cell === row.requiredMatrix) {
        formattedText = row.formattedRequiredMatrix;
    }

    return <TextTooltip placement='bottom' text={cell} formattedText={formattedText}/>
}
