import React from 'react';
import {saveMoveToStage} from "../../../redux/actions/cases/caseActions";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {
    closeCreateModal,
    closeEditModal,
    closeDeleteModal,
    openDeleteModal
} from "../../../redux/action-creators/users";
import DeleteModal from "../../DeleteModal";
import Modal from "react-modal";

class NavBar extends React.Component {

    componentDidMount() {
        Modal['setAppElement']('body');
        this.props.handleCloseModals();
    }

    goto = (stage) => {
        if (this.props.currentRole.indexOf('ROLE_REVIEWER') === -1 &&
            this.props.currentRole.indexOf('ROLE_CLIENT') === -1) {
            if (this.props.case.currentStage.code) {
                this.props.saveMoveToStage(stage);
                this.props.handleOpenDeleteModal();
            }
        }
    };

    render() {
        let navBarFixedClass = this.props.sidebarCollapsed ? 'navBarFixed' : 'navBarFixedExpand';
        const disabledForm = this.props.disabled || this.props.isLocked ? {pointerEvents: 'none'} : {};
        const cases = this.props.case;
        const context = this.context;

        return (
            <div className="headerNavBar" id="headerNavBar" style={disabledForm}>
                <div className={'row ' + navBarFixedClass} id="navBarFixed">
                    <div className="col-xs-12">
                        <div className="box box-primary box-nav-list" style={{marginBottom: 0}}>
                            {typeof (cases.currentStage.code) !== undefined ?
                                <div className="nav-list">
                                    <div onClick={() => {
                                        this.goto('draft')
                                    }}
                                         className={['CW_DRAFT', 'SW_DRAFT'].indexOf(cases.currentStage.code) === -1
                                             ? 'nav-list__item' : 'nav-list__item active'}>
                                        <i className="nav-list__icon fa fa-file-text-o"> </i>&nbsp;
                                        {context.translate('common.case.draft')}
                                    </div>
                                    <div onClick={() => {
                                        this.goto('dispatch')
                                    }}
                                         className={['CW_DISPATCH', 'SW_DISPATCH'].indexOf(cases.currentStage.code) === -1
                                             ? 'nav-list__item' : 'nav-list__item active'}>
                                        <i className="nav-list__icon fa fa-hand-o-right"> </i>&nbsp;
                                        {context.translate('common.case.dispatch')}
                                    </div>
                                    <div onClick={() => {
                                        this.goto('review')
                                    }}
                                         className={['CW_REVIEW', 'SW_REVIEW'].indexOf(cases.currentStage.code) === -1
                                             ? 'nav-list__item' : 'nav-list__item active'}>
                                        <i className="nav-list__icon fa fa-user-md"> </i>&nbsp;
                                        {context.translate('common.case.review')}
                                    </div>
                                    <div onClick={() => {
                                        this.goto('qa')
                                    }}
                                         className={['CW_QA', 'SW_QA'].indexOf(cases.currentStage.code) === -1
                                             ? 'nav-list__item' : 'nav-list__item active'}>
                                        <i className="nav-list__icon fa fa-search"> </i>&nbsp;
                                        {context.translate('common.case.qa')}
                                    </div>
                                    <div onClick={() => {
                                        this.goto('closed')
                                    }}
                                         className={['CW_CLOSED', 'SW_CLOSED'].indexOf(cases.currentStage.code) === -1
                                             ? 'nav-list__item' : 'nav-list__item active'}>
                                        <i className="nav-list__icon fa fa-check-square-o"> </i>&nbsp;
                                        {context.translate('common.case.closed')}
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
                <DeleteModal
                    userId={this.props.case.code}
                    operation='move_to'
                    editTarget='case'
                    title='Move to...'
                />
            </div>
        )
    }
}

NavBar.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {
    return {
        isLocked: state.app.cases.caseLockInfo.locked,
        currentRole: state.app.auth.user.roleCode,
        case: state.app.cases.editedCase,
        sidebarCollapsed: state.app.menu.sidebarCollapsed,
    }
};

const mapActions = dispatch => {
    return {
        handleOpenDeleteModal: () => dispatch(openDeleteModal()),
        saveMoveToStage: (stage) => dispatch(saveMoveToStage(stage)),
        handleCloseModals: () => {
            dispatch(closeCreateModal());
            dispatch(closeEditModal());
            dispatch(closeDeleteModal());
        }
    }
};

export default connect(mapState, mapActions)(NavBar);
