import React from 'react';
import { detachFile, getCase, getCaseFiles, saveTableWidth, updateFile } from "../../../redux/actions/cases/caseActions";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { getReportsFromSection } from 'redux/actions/cases/caseReportsActions';
import { isClient, isReviewer } from "../../../utils/helpers";
import { withRouter } from "react-router-dom";
import LoadingIndicator from "../../../components/LoadingIndicator";

import MedicalDropZone from 'components/Controls/MedicalDropZone';

import { API_HOST } from '../../../config';
import FilesModal from "../../FilesModal";
import { closeFilesModal, openFilesModal, saveDeletedCode } from "../../../redux/action-creators/users";
import api from "../../../utils/api";
import { MIME_TYPES } from "../../../constants/mimeTypes";
import FileDownload from "react-file-download";
import SystemMessage from '../../../components/SystemMessage';
import CaseUploadFile from '../Buttons/CaseUploadFile';

function isFilteredData(item, attr, value) {
    if (!item.hasOwnProperty(attr)) return true;
    if (typeof item[attr] === "undefined") return false;
    if (typeof item[attr] === "boolean") item[attr] += '';
    if (typeof item[attr] !== "string") return false;
    return (item[attr].toLowerCase().indexOf(value.toLowerCase()) !== -1);
}


class MedicalRecords extends React.Component {

    constructor(props) {
        super(props);
        let sectionName = (typeof this.props.type === "undefined") ? 'files' : this.props.type.toLowerCase();
        let buttonName = 'upload_files';
        this.state = {
            sectionName: sectionName,
            buttonName: buttonName,
            files: props[sectionName],
            firstLoad: true,
            showFilters: false,
            sorting: {
                sortName: 'name',
                sortOrder: 'asc'
            },
            tableWidth: 100,
            '--attachments--state': false,
            dropZoneFiles: null,
        };

        this.onDetachFile = this.onDetachFile.bind(this);
        this.onEditCell = this.onEditCell.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.onBlockSection = this.onBlockSection.bind(this);
        this.onSaveFiles = this.onSaveFiles.bind(this);
    }

    // TODO: deprecated react method
    componentWillReceiveProps(newProps) {
        if (JSON.stringify(newProps.files) !== JSON.stringify(this.props.files)) {
            this.setState({ files: newProps[this.state.sectionName] });
            setTimeout(() => {
                this.onSortChange(this.state.sorting.sortName, this.state.sorting.sortOrder);
                this.setState({ firstLoad: false });
            }, 20);
        }
        if (JSON.stringify(newProps.sidebarCollapsed) !== JSON.stringify(this.props.sidebarCollapsed)) {
            let timer = setInterval(() => {
                this.updateDimensions();
            }, 1);
            setTimeout(function () {
                clearInterval(timer);
            }, 500);
        }
    }

    onBlockSection(row, url, paramName, code) {
        const blocker = document.getElementById('blockerContainerFiles');
        const blocked = document.getElementById('blockedContainerFiles');
        if (blocker && blocked) {
            const width = blocked.offsetWidth;
            const height = blocked.offsetHeight;
            const marginTop = height + 20;
            blocker.style.width = width + 'px';
            blocker.style.height = height + 'px';
            blocker.style.marginTop = '-' + marginTop + 'px';
            blocker.style.display = 'block';
        }
        api.request({
            url: url,
            params: { [paramName]: code },
            responseType: 'arraybuffer',
            headers: {
                Accept: [MIME_TYPES.octetStream, MIME_TYPES.docx],
            },
        }).then((res) => {
            FileDownload(res.data, row.name);
            blocker.style.display = 'none';
        }).catch((err) => {
            console.error(err);
            blocker.style.display = 'none';
        });
    }

    updateDimensions() {
        let attachmentBody = document.getElementById('attachment-body');
        let tableWidth = this.props.tableWidth;
        if (attachmentBody) {
            tableWidth = attachmentBody.getBoundingClientRect().width - 85;
        }
        this.props.saveTableWidth(tableWidth);
    }

    componentDidMount() {
        this.props.handleCloseModals();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    onDetachFile(code) {
        this.props.saveDeletedCode(code, 'file');
        this.props.handleOpenFilesModal();
    }

    isCaseClosed = () => {
        return this.props.case.currentStage.code === "CW_CLOSED";
    };

    linkFormatter = (cell, row) => {
        if (row.isMerge) {
            return (
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                <a
                    download={false}
                    rel={'noopener noreferrer'}
                    onClick={() => {
                      this.onBlockSection(row, `${API_HOST}/case/download_merged_attachments`, 'caseCode', this.props.case.code);
                    }}
                    title={cell}
                >{cell}</a>
            );
        } else {
            return (
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                <a
                    download={false}
                    rel={'noopener noreferrer'}
                    onClick={() => {
                      this.props.getReportsFromSection(row.code);
                    }}
                    title={cell}
                >{cell}</a>
            );
        }
    };

    mergeFormatter = (cell, row) => {
        if (row.isMerge) return '';
        let isClosed = this.isCaseClosed();
        return (
            <input type="checkbox" disabled={isClosed || this.props.isLocked} id={row.code} checked={cell} onChange={() => { }}
                onClick={() => {
                    this.onEditCell(row, 'includedToMerge')
                }}
            />
        );
    };

    countedFormatter = (cell, row) => {
        if (row.isMerge) return '';
        let isClosed = this.isCaseClosed();
        return (
            <input type="checkbox" disabled={row.includedToMerge || isClosed || this.props.isLocked} id={row.code} checked={cell} onChange={() => {
            }}
                onClick={() => {
                    this.onEditCell(row, 'counted')
                }}
            />
        );
    };

    deleteFormatter = (cell, row) => {
        if (this.props.currentStageCode.indexOf('CLOSED') > 0) return '';
        if (!row['canRemove'] || this.props.isLocked) return '';
        if ((isClient(this.props.currentUser.roleCode) || isReviewer(this.props.currentUser.roleCode))
            && this.props.currentUser.code !== row.userCode) return '';
        if (isClient(this.props.currentUser.roleCode) &&
            this.props.currentStageCode.indexOf('DRAFT') === -1) {
            return '';
        }
        if (row.isMerge) return '';
        else return (
            <button className="btn btn-default margin-left-5" onClick={() => {
                this.onDetachFile(row.code)
            }}><i className="fa fa-times"> </i></button>
        );
    };

    onSortChange = (sortName, sortOrder) => {
        const sorting = { sortName, sortOrder };

        let files = [...this.state.files];
        let first = [];
        if (typeof files[0] !== "undefined" && files[0]['isMerge']) {
            first = files.shift();
        }
        if (sortName === 'sizeKB') sortName = 'size';
        if (sortName === 'date') sortName = 'date_original';
        files.sort(function (a, b) {
            let a_field = typeof a[sortName] === "string" ?
                (a[sortName] != null ? a[sortName].toLowerCase() : ' ')
                : a[sortName];
            let b_field = typeof b[sortName] === "string" ?
                (b[sortName] != null ? b[sortName].toLowerCase() : ' ')
                : b[sortName];
            if (a_field > b_field) {
                return sortOrder === 'asc' ? 1 : -1;
            } else if (a_field < b_field) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            return 0;
        });

        this.setState({ files: first.length === 0 ? files : [first].concat(files), sorting });
    };

    handleFilterChange = (filters) => {
        let data;

        for (let colFiltered in filters) {
            if (!filters.hasOwnProperty(colFiltered)) continue;

            let searchData = this.state.files.filter((item) => isFilteredData(item, colFiltered, filters[colFiltered].value));
            data = [...searchData];
        }

        this.setState({ files: data || this.props[this.state.sectionName] });
    };

    onEditCell(row, cellName, cellValue) {
        if (row.isMerge) return '';
        if (this.isCaseClosed()) return '';
        let files = this.state.files;
        let file = row;
        if (cellName === 'counted') {
            row.counted = !row.counted;
        }
        if (cellName === 'includedToMerge') {
            row.includedToMerge = !row.includedToMerge;
            row.counted = row.includedToMerge ? true : row.counted;
        }
        if (row.counted && !row.includedToMerge) {
            row.visible = 'All';
            row.visibleToRoles = [
                'ROLE_REVIEWER',
                'ROLE_STAFF_QA',
                'ROLE_STAFF_BASIC',
                'ROLE_STAFF_MANAGER',
                'ROLE_STAFF_ACCOUNTANT',
                'ROLE_CLIENT_ADMIN',
                'ROLE_CLIENT_USER',
                'ROLE_CLIENT_SUPERVISOR',
                'ROLE_ADMIN'
            ];
        }
        let visible = row.visibleToRoles;

        if (cellName === 'visible') {
            visible = [];
            if (cellValue === 'All') {
                visible.push('ROLE_REVIEWER');
                visible.push('ROLE_STAFF_QA');
                visible.push('ROLE_STAFF_BASIC');
                visible.push('ROLE_STAFF_MANAGER');
                visible.push('ROLE_STAFF_ACCOUNTANT');
                visible.push('ROLE_CLIENT_ADMIN');
                visible.push('ROLE_CLIENT_USER');
                visible.push('ROLE_CLIENT_SUPERVISOR');
                visible.push('ROLE_ADMIN');
            }
            if (cellValue === 'Staff') {
                visible.push('ROLE_ADMIN');
                visible.push('ROLE_STAFF_QA');
                visible.push('ROLE_STAFF_BASIC');
                visible.push('ROLE_STAFF_MANAGER');
                visible.push('ROLE_STAFF_ACCOUNTANT');
                if (!row.includedToMerge) {
                    row.counted = false;
                }
            }
            if (cellValue === 'Client') {
                visible.push('ROLE_ADMIN');
                visible.push('ROLE_STAFF_QA');
                visible.push('ROLE_STAFF_BASIC');
                visible.push('ROLE_STAFF_MANAGER');
                visible.push('ROLE_STAFF_ACCOUNTANT');
                visible.push('ROLE_CLIENT_ADMIN');
                visible.push('ROLE_CLIENT_USER');
                visible.push('ROLE_CLIENT_SUPERVISOR');
                if (!row.includedToMerge) {
                    row.counted = false;
                }

            }
            if (cellValue === 'Reviewer') {
                visible.push('ROLE_REVIEWER');
                visible.push('ROLE_ADMIN');
                visible.push('ROLE_STAFF_QA');
                visible.push('ROLE_STAFF_BASIC');
                visible.push('ROLE_STAFF_MANAGER');
                visible.push('ROLE_STAFF_ACCOUNTANT');
            }

            row.visibleToRoles = visible;
        }
        let obj = {
            attachmentCode: row.code,
            settings: {
                code: row.code,
                date: Date.now(),
                type: row.type,
                name: row.name,
                counted: row.counted === true || row.counted === "true",
                includedToMerge: row.includedToMerge === true || row.includedToMerge === "true",
                visibleToRoles: visible
            }
        };
        let resultFiles = [];
        for (let i = 0; i < files.length; i++) {
            if (files[i].code !== row.code) {
                resultFiles.push(files[i]);
            } else {
                resultFiles.push(file);
            }
        }
        this.setState({ files: resultFiles });
        this.props.updateFile(obj).then(() => {
            this.props.getCase(this.props.match.params.id).then(() => {
                this.props.getCaseFiles(this.props.match.params.id);
            })
        });
    }

    changeFilters = () => {
        this.setState({ 'showFilters': !this.state.showFilters });
    };

    filtered = (item) => {
        if (this.state.showFilters) {
            if (item.filter && item.filter.type === 'TextFilter') {
                item.filter.placeholder = this.context.translate('common.form.enter') + ' ' + item.title;
            }
            return item.filter;
        }
        return null;
    };

    onSaveFiles(files) {
        this.setState({ dropZoneFiles: files });
        this.state.dropZoneFiles = files;
    }

    render() {
        let buttonName = this.state.buttonName;
        const { modify_cases, caseMedicalRecords, loading, tableWidth, filesError } = this.props;
        let sortingConf = {
            sortName: caseMedicalRecords.sorting.sortName,
            sortOrder: caseMedicalRecords.sorting.sortOrder
        };
        let paginationConf = {
            page: caseMedicalRecords.pagination.page,
            sizePerPage: caseMedicalRecords.pagination.sizePerPage
        };

        let isEditable = !(isClient(this.props.currentUser.roleCode) || isReviewer(this.props.currentUser.roleCode))
            && !this.isCaseClosed();
        let colsOnly4Staff = modify_cases ? [{
            'name': 'counted',
            'title': this.context.translate('common.form.count'),
            'width': '100',
            'columnTitle': () => {
                return this.context.translate('common.form.count')
            },
            'dataFormat': this.countedFormatter,
            'sort': false,
            'editable': false,
            'filter': {
                type: 'SelectFilter', options: {
                    'false': this.context.translate('common.form.no'),
                    'true': this.context.translate('common.form.yes')
                }, selectText: this.context.translate('common.form.select')
            }
        }, {
            'name': 'includedToMerge',
            'title': this.context.translate('common.form.merged_doc'),
            'width': '100',
            'columnTitle': () => {
                return this.context.translate('common.form.merged_doc')
            },
            'dataFormat': this.mergeFormatter,
            'sort': false,
            'editable': false,
            'filter': {
                type: 'SelectFilter', options: {
                    'false': this.context.translate('common.form.no'),
                    'true': this.context.translate('common.form.yes')
                }, selectText: this.context.translate('common.form.select')
            }
        }, {
            'name': 'visible',
            'title': this.context.translate('common.form.visible'),
            'width': '120',
            'columnTitle': true,
            'sort': false,
            'columnClassName': 'visible-col',
            'editable': isEditable ? {
                'type': 'select',
                'options': {
                    'values': ['All', 'Staff', 'Client', 'Reviewer']
                }
            } : false,
            'filter': { type: 'TextFilter', delay: 500 },
            'filterFormatted': true
        }] : [];
        let colsOnly4Staff2 = modify_cases ? [{
            'name': 'user',
            'title': this.context.translate('common.form.user'),
            'width': '180',
            'columnTitle': true,
            'sort': true,
            'editable': false,
            'filter': { type: 'TextFilter', delay: 500 }
        }, {
            'name': 'userType',
            'title': this.context.translate('common.form.user_type'),
            'width': '100',
            'columnTitle': true,
            'sort': true,
            'editable': false,
            'filter': { type: 'TextFilter', delay: 500 }
        }] : [];
        let colsCommon = [
            {
                'name': 'name',
                'title': this.context.translate('common.form.document_name'),
                'width': '220',
                'columnTitle': (row) => {
                    return row;
                },
                'sort': true,
                'editable': false,
                'dataFormat': this.linkFormatter,
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'sizeKB',
                'title': this.context.translate('common.form.size'),
                'width': '100',
                'columnTitle': true,
                'sort': true,
                'editable': false,
                'filter': { type: 'TextFilter', delay: 500 }
            }, {
                'name': 'date_original',
                'title': this.context.translate('common.form.date'),
                'dataFormat': (col, row) => {
                    return row.date
                },
                'width': '180',
                'columnTitle': true,
                'sort': true,
                'editable': false,
                'filter': { type: 'TextFilter', delay: 500 }
            }
        ];
        let colsPages = [{
            'name': 'pageCount',
            'title': this.context.translate('common.form.pages'),
            'width': '100',
            'columnTitle': true,
            'sort': true,
            'editable': false,
            'filter': { type: 'TextFilter', delay: 500 }
        }];

        let cols = [{
            'isKey': true,
            'name': 'key',
            'columnTitle': 'Delete',
            'width': '100',
            'dataAlign': 'center',
            'sort': false,
            'editable': false,
            'dataFormat': this.deleteFormatter
        }];
        if (this.isCaseClosed()) {
            cols = [{
                'isKey': true,
                'name': 'key',
                'hidden': true,
                'editable': false
            }];
        }

        if (isClient(this.props.currentUser.roleCode) || isReviewer(this.props.currentUser.roleCode)) {
            cols = colsCommon.concat(colsPages).concat(cols);
        } else {
            cols = colsCommon.concat(colsOnly4Staff2).concat(colsPages).concat(colsOnly4Staff).concat(cols);
        }

        const options = {
            onFilterChange: this.handleFilterChange,
            onSortChange: this.onSortChange,
            defaultSortName: sortingConf.sortName,
            defaultSortOrder: sortingConf.sortOrder,
            sortName: this.state.sorting.sortName,
            sortOrder: this.state.sorting.sortOrder,
            sizePerPage: paginationConf.sizePerPage
        };
        if (this.state.firstLoad) {
            this.onSortChange(this.state.sorting.sortName, this.state.sorting.sortOrder);
            this.setState({ firstLoad: false });
        }

        const defaultSorting = {
            sortName: 'name',
            sortOrder: 'asc'
        };

        let caseCode = this.props.case.code;

        let attachmentBody = document.getElementById('attachment-body');
        if (attachmentBody) {
            let tableWidthNew = attachmentBody.getBoundingClientRect().width - 85;
            if (tableWidth !== tableWidthNew) {
                this.props.saveTableWidth(tableWidthNew);
            }
        }

        let fileUploadErrorMessage = this.props.filesError && this.props.filesError.length > 0 ?
            'The following files could not be uploaded, because the combined size of the files would be larger than ' + this.props.fileUploadLimit + '. Try uploading them separately: ' :
            null;
        if (this.props.filesError && this.props.filesError.length > 0) {
            const failedFilesList = this.props.filesError.map((file) => {
                const fileName = file.name;
                const size = (file.size / 1000000).toFixed(2);
                return `${fileName}: ${size} MB`;
            });

            if (fileUploadErrorMessage && failedFilesList) {
                fileUploadErrorMessage += failedFilesList.join(', ');
            }
        }

        return (
            <MedicalDropZone
                onChange={this.onSaveFiles}
                name={this.context.translate('common.case.' + buttonName)}
                fileName={this.context.translate('common.case.' + buttonName)}
                accept={'image/jpeg,image/png,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.doc,.docs,.ppt,.pptx,.pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation'}
            >
                <SystemMessage type='error' shown={fileUploadErrorMessage && fileUploadErrorMessage.length > 0} message={fileUploadErrorMessage} />
                <div className="box box-primary" id={"blockedContainerFiles"} style={{ position: 'relative' }}>
                    <div className="box-header with-border undefined attachments-header">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a id="_attachments_">
                            <h3 className="box-title text-blue undefined">
                                <i className="fa fa-file-pdf-o text-blue margin-right-5"> </i>{this.props.name}
                            </h3>
                        </a>

                        <div className="box-tools pull-right toggle-box"
                            style={{ width: '60px', float: 'right !important', position: 'relative' }}>
                            <button type="button" className="btn btn-box-tool toggle-btn remove-reviewer-data"
                                style={{ height: '50px' }}
                                onClick={() => {
                                    let sectionState = {
                                        '--attachments--state': !this.state['--attachments--state']
                                    };
                                    this.setState(sectionState);
                                }}>
                                <i className={`fa toggle-fa-icon-reviewer-info ${this.state['--attachments--state'] ? 'fa-plus' : 'fa-minus'}`}> </i>
                            </button>
                        </div>

                        <CaseUploadFile dropZoneFiles={this.state.dropZoneFiles} buttonName={buttonName} hidden={this.state['--attachments--state']}></CaseUploadFile>
                    </div>
                    <div className="box-body" id="attachment-body"
                        style={{ display: this.state['--attachments--state'] ? 'none' : 'block', position: 'relative' }}>

                        <div style={{ float: 'left' }}>
                            <button
                                className={`btn btn-sm resizable-button tooltip-button btn-primary`}
                                style={{ marginRight: '10px' }}
                                onClick={this.changeFilters}>
                                <i className={`fa fa-filter`} />
                            </button>
                            <button
                                className={`btn btn-sm resizable-button tooltip-button btn-primary`}
                                style={{ marginRight: '10px', display: 'block', marginTop: '10px' }}
                                onClick={() => this.onSortChange(defaultSorting.sortName, defaultSorting.sortOrder)}>
                                <i className={`fa fa-sort`} style={{ padding: '1.5px' }} />
                            </button>
                        </div>

                        <div className='margin-bottom-15 attachments-table' style={{ width: tableWidth + 'px' }}>
                            <BootstrapTable
                                striped
                                hover
                                remote={true}
                                options={options}
                                data={this.state.files}
                                total={this.state.files.length}
                                pagination={true}
                                search={false}
                                //headerContainerClass='without_top_border_header'
                                bordered={true}
                                //defaultSort
                                cellEdit={{
                                    mode: 'click',
                                    blurToSave: true,
                                    beforeSaveCell: (row) => {
                                        if (row.isMerge) return '';
                                    },
                                    afterSaveCell: this.onEditCell,
                                    nonEditableRows: function () {
                                        return ['Records_' + caseCode, '0', 0];//files.filter(p => p['isMerged']).map(p => p.key);
                                    }
                                }}
                            >
                                {
                                    cols.map((item, i) => (
                                        <TableHeaderColumn
                                            {...item}
                                            key={i}
                                            dataField={item.name}
                                            sort={String(item.sort)}
                                            dataSort={item.sort}
                                            width={String(item.width)}
                                            filter={this.filtered(item)}
                                        >
                                            {item['title']}
                                        </TableHeaderColumn>
                                    ))
                                }
                            </BootstrapTable>
                        </div>

                        {loading ? <LoadingIndicator height={50} /> : ''}

                    </div>
                    <FilesModal
                        editTarget='case'
                        title='Delete File'
                    />
                </div>
                <div id="blockerContainerFiles" />
            </MedicalDropZone>
        )
    }
}

MedicalRecords.contextTypes = {
    translate: PropTypes.func
};

const mapState = (state) => {

    let cases = state.app.cases;
    let users = state.app.users.users;
    let my_permissions = state.app.users.my_permissions;
    return {
        filesError: state.app.cases.editedCase?.fileUploadErrors,
        isLocked: state.app.cases.caseLockInfo.locked,
        fileUploadLimit: state.app.auth.user.fileUploadLimit,
        loading: state.app.cases.loading_files,
        currentUser: state.app.auth.user,
        modify_cases: my_permissions.includes('MODIFY_CASES'),
        currentStageCode: state.app.cases.editedCase.currentStage.code,
        case: state.app.cases.editedCase,
        files: cases.files,
        medical_records: cases.medical_records
            .map(item => {
                let user = users.find(entity => item.userCode === entity.code);
                let userType = '';
                if (user) {
                    if (isClient(user.roleCode)) userType = 'Client';
                    else if (isReviewer(user.roleCode)) userType = 'Reviewer';
                    else userType = 'Staff';
                }
                return {
                    ...item,
                    userType: userType
                }
            }),
        assignmentDetails: state.app.case_assign.appliedReviewers,
        sidebarCollapsed: state.app.menu.sidebarCollapsed,
        tableWidth: state.app.cases.tableWidth,

        caseMedicalRecords: state.app.grid.caseMedicalRecords
    }
};

const mapActions = dispatch => {
    return {
        handleCloseModals: () => {
            dispatch(closeFilesModal())
        },
        getCase: (code) => dispatch(getCase(code)),
        getCaseFiles: (code) => dispatch(getCaseFiles(code)),
        handleOpenFilesModal: () => dispatch(openFilesModal()),
        saveDeletedCode: (code, type) => dispatch(saveDeletedCode(code, type)),
        updateFile: (data) => dispatch(updateFile(data)),
        detachFile: (code) => dispatch(detachFile(code)),
        saveTableWidth: (width) => dispatch(saveTableWidth(width)),
        getReportsFromSection: (code) => dispatch(getReportsFromSection(code, false, false))
    }
};

export default connect(mapState, mapActions)(withRouter(MedicalRecords));
