import axios from 'axios/index';
import api from "../../../utils/api";
import apiFile from "../../../utils/api";
import { API_HOST } from '../../../config';
import { Statuses } from "../statuses";
import { store } from "../../store";
import { getCaseFiles, setCaseMessage, getPatientCaseLock } from "./caseActions";
import { getSessionStorageKey } from "utils/case-helpers";
import fileDownload from "react-file-download";
import { MIME_TYPES } from "../../../constants/mimeTypes";

export const saveCaseReport = (code, isReport, latestReport) => {
    let href = `${API_HOST}/reports/download?code=${code}&isReport=${isReport ? isReport : false}&latestReport=${latestReport ? latestReport : false}`;
    return axios.get(href, {
        responseType: 'blob',
        timeout: 30000,
        withCredentials: true
    })
        .then(({ data }) => {
            if (data && typeof data.size !== "undefined" && data.size > 0) {
                let a = document.createElement('a');
                a.download = true;
                a.href = href;
                a.click();
            } else {
                store.dispatch(setCaseMessage({
                    type: 'error',
                    text: 'The case has no report or you do not have permission to download it.',
                    time: 5000
                }))
            }
        })
        .catch(() => {
            store.dispatch(setCaseMessage({
                type: 'error',
                text: 'The case has no report or you do not have permission to download it.',
                time: 5000
            }));
        })
};

export const saveMultipleCaseReports = (attachmentDtos) => {
    let href = `${API_HOST}/reports/downloadMultiple`;

    return axios.post(href, attachmentDtos, {
        responseType: 'blob',
        timeout: 30000,
        withCredentials: true
    })
        .then(({ data }) => {
            if (data && typeof data.size !== "undefined" && data.size > 0) {
                const a = document.createElement('a');
                a.download = "reports";
                const url = window.URL.createObjectURL(data);
                a.href = url;
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                store.dispatch(setCaseMessage({
                    type: 'error',
                    text: 'The case has no reports or you do not have permission to download it.',
                    time: 5000
                }))
            }
        })
        .catch(() => {
            store.dispatch(setCaseMessage({
                type: 'error',
                text: 'The case has no reports or you do not have permission to download it.',
                time: 5000
            }));
        })
};

export const getReportsFromSection = (attachmentCode, isReport, latestReport) => (dispatch) => {
    /*const blocker = document.getElementById('blockerContainerFiles');
    const blocked = document.getElementById('blockedContainerFiles');
    if (blocker && blocked) {
        const width = blocked.offsetWidth;
        const height = blocked.offsetHeight;
        const marginTop = height + 20;
        blocker.style.width = width + 'px';
        blocker.style.height = height + 'px';
        blocker.style.marginTop = '-' + marginTop + 'px';
        blocker.style.display = 'block';
    }*/
    dispatch({
        type: 'FETCH_CASE_LOADING',
    });
    return Promise.all(saveCaseReport(attachmentCode, isReport, latestReport))
        .then(() => dispatch({ type: 'FETCH_CASE_LOADED_SUCCESS' }))
        .catch((err) => dispatch({
            type: 'FETCH_CASE_ERROR',
            payload: err.message
        }))
};

export const getLastReportForClient = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CASE_LOADING',
    });
    const { code } = getState().app.cases.editedCase;
    return Promise.all(saveCaseReport(code, true, true))
        .then(() => dispatch({ type: 'FETCH_CASE_LOADED_SUCCESS' }))
        .catch((err) => dispatch({
            type: 'FETCH_CASE_ERROR',
            payload: err.message
        }))
};

export const getReportFromGridClient = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CASE_LOADING',
    });

    const { patientCase, case_list } = getState().app;

    const all = Object.values(patientCase).reduce((all, current) => [...all, ...current.data], [])

    const caseIds = all.reduce((p, currentCase) => {
        return {
            [ currentCase.code ]: currentCase.caseId,
            ...p
        }
    }, {});

    if (case_list.selectedCaseCodes.length > 1) {
        let attachmentDtos = [];
        case_list.selectedCaseCodes.map((code) => {
            attachmentDtos.push({ 
                code,
                isReport: true,
                latestReport: true,
            });
        });
    
        return Promise.all(saveMultipleCaseReports(attachmentDtos))
            .then(() => dispatch({ type: 'FETCH_CASE_LOADED_SUCCESS' }))
            .catch((err) => dispatch({
                type: 'FETCH_CASE_ERROR',
                payload: err.message
            }));
    } else {
        return Promise.all(
            case_list.selectedCaseCodes.map((code) => {
                    return saveCaseReport(code, true, true)
            }))
            .then(() => dispatch({ type: 'FETCH_CASE_LOADED_SUCCESS' }))
            .catch((err) => dispatch({
                type: 'FETCH_CASE_ERROR',
                payload: err.message
            }))
    }
};

export const deactivateReport = code => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_DEACTIVATE_REPORT_LOADING',
    });

    // let item = getState().app.cases.files.find((item) => {
    //     if (item.code === code.code) return item;
    // });
    // const oldItem = {...item};

    const caseCode =  getState().app.cases.editedCase.code;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(caseCode) + '_tabCreatedAt');

    return apiFile.get(`reports/deactivate_report?attachmentCode=${code.code}&caseCode=${caseCode}&tabTimeStamp=${tabTimeStamp}`)
        .then(() => {
            // dispatch({
            //     type: 'FETCH_DEACTIVATE_REPORT',
            //     payload: {
            //         files: getState().app.cases.files,
            //         oldItem: oldItem,
            //         pagesCount: getState().app.cases.pagesCount
            //     }
            // });
            dispatch({
                type: 'FETCH_DEACTIVATE_REPORT_SUCCESS',
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_DEACTIVATE_REPORT_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ]
            })
            if(err?.response?.status == 423) {
              getPatientCaseLock(code)(dispatch);
            } 
          })
};

export const generateInternalCaseFile = code => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_GENERATE_INTERNAL_LOADING',
    });

    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');

    return apiFile.get(`reports/generate_internal_case_file?caseCode=${code}&tabTimeStamp=${tabTimeStamp}`)
        .then((res) => {
            dispatch({
                type: 'FETCH_GENERATE_INTERNAL_SUCCESS',
                payload: res.data[ 'internalCaseReportCode' ] || ''
            });

            dispatch(getCaseFiles(code));
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_GENERATE_INTERNAL_ERROR',
                // payload: Statuses[err.response] || Statuses[0]
                payload: err.response.data
            })
              if(err?.response?.status == 423) {
                getPatientCaseLock(code)(dispatch);
              }
              console.log(err.response.data);
       })
};

export const uploadReport = (data) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_ATTACH_CASE_FILE_LOADING',
    });

    let formData = new FormData();
    formData.append('report', data.file);

    const {code} = getState().app.cases.editedCase;
    const tabTimeStamp = sessionStorage.getItem(getSessionStorageKey(code) + '_tabCreatedAt');

    return apiFile.post(`reports/upload?caseCode=${data.code}&tabTimeStamp=${tabTimeStamp}`, formData)
        .then(({ config }) => {
            dispatch({
                type: 'FETCH_CASE_FILE_SUCCESS',
            })
        })
        .catch((err) => {
            dispatch({
                type: 'FETCH_CASE_FILE_ERROR',
                payload: Statuses[ err.response ] || Statuses[ 0 ]
            })
            if(err?.response?.status == 423) {
              const {code} = getState().app.cases.editedCase;
              getPatientCaseLock(code)(dispatch);
            } 
          })
};

export const getMergedDocForReviewer = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_CASE_LOADING',
    });
    const { code, caseId } = getState().app.cases.editedCase;
    return api.request({
        url: 'case/download_merged_attachments',
        params: { 'caseCode': code },
        responseType: 'arraybuffer',
        headers: {
            Accept: [MIME_TYPES.octetStream],
        },
    }).then((response) => {
        let contentType = response.headers[ "content-type" ];
        fileDownload(response.data, 'Records_' + caseId, contentType);
        dispatch({
            type: 'FETCH_CASE_LOADED_SUCCESS',
        })
    }).catch((error) => dispatch({
        type: 'FETCH_CASE_ERROR',
        payload: error.message
    }));
};
